import { Avatar, Box, Drop, Text, Tip } from "grommet"
import { Alert, CircleInformation, User } from "grommet-icons"
import { GrHide } from "react-icons/gr"
import { GiSandsOfTime } from "react-icons/gi"
import moment from 'moment'

import "./CommentBox.css"
import { FaUserCircle } from "react-icons/fa"
import { ExpandingButton } from "../ExpandingButton/expandingButton"
import { MdError } from "react-icons/md"
import { useEffect, useMemo, useState } from "react"
import getApi from "../../Middleware/apiService"

const MiniBadge = ({ children }) => {
    return (
        <Box direction="row" border round flex="shrink" pad="2px 5px" align="center" justify="center" gap="2px">
            {children}
        </Box>
    )
}


export const CommentBox = ({ comment, mode, onExplainPredictionsClick, label_settings }) => {
    const full_mode="admin"
    const [drop, setDrop]=useState()
    const [commentOverride, setCommentOverride]=useState()

    const theComment=useMemo(()=> commentOverride||comment,[comment,commentOverride])

    function modifyLabel(target){
        if(label_settings){

            setDrop(<Drop target={target} 
                onClickOutside={() => setDrop()}>
                {Object.keys(label_settings).map(l=>
                    <Box direction="row" focusIndicator={false} 
                    gap="small"
                    pad="2px"
                    onClick={()=>{
                        setDrop()
                        
                        getApi().setLabel(comment._id, l).then(c=>{
                            setCommentOverride(c)
                        })
                    }}>
                        <Text size="small">{label_settings[l]?.icon}</Text>
                        <Text size="small">{l}</Text>
                    </Box>
                )}
            </Drop>)
        }
    }
    return (
        <Box width="100%" margin="10px 0px">
            {drop}
            
            <Box align={theComment.author.id == theComment.social_account ? "end" : "start"}  >
                <Box direction="row">
                    {theComment.author.id !== theComment.social_account && (
                        <Box pad="0px 5px" flex="grow">
                            <FaUserCircle size="30px" color="lightgray" />
                        </Box>

                    )}
                    <Box>
                        <Box direction="row" gap="5px" >

                            <Text size="8pt">{theComment.author?.name}  {theComment.author.id == theComment.social_account ? "( author )" : ""} </Text>
                            <Box round background="rgb(240,240,240)" pad="2px 5px">

                                <Tip content={<Text size="10pt"> {moment(theComment.timestamp).format("LLL")}</Text>}><Text size="8pt">{moment(theComment.timestamp).fromNow()}</Text></Tip>
                            </Box>
                            <Tip content={<Text size="8pt">{`Id: ${theComment._id} (Copy)`}</Text>}>
                                <Box focusIndicator={false} margin="1px" pad="0px 2px" onClick={()=>navigator.clipboard.writeText(theComment._id)} >
                                        <CircleInformation size="small"/>
                                </Box>
                            </Tip>
                            {theComment.error&&(
                                <Tip content={<Text size="small"> {theComment.error||"test"}</Text>}>
                                    <Box>
                                        <MdError color="red"/>
                                    </Box>
                                </Tip>
                                
                            )}

                        </Box>

                        <Box  align="start" >
                            <Box className={theComment.author.id == theComment.social_account ? "authorsCommentBox" : "commentBox"} onClick={()=>window.open(theComment.permalink ,"_blank")} >
                            <Text size="small" color={theComment.text &&theComment.is_public ? undefined:"gray"}>{theComment.text ||"[no text]"}</Text>
                            <Box>
                                {theComment.attachment?.media?.image?(
                                    <img src={theComment.attachment.media.image.src} alt={theComment.attachment?.title} height="150px"/>
                                ):(
                                    <></>
                                )}
                            </Box>
                            </Box>
                            {mode==full_mode?(
                            theComment.author.id != theComment.social_account && (


                                <Box direction="row" align="center" justify="start" focusIndicator={false} onClick={(e)=>label_settings?modifyLabel(e.target):undefined}>

                                    {theComment.saving?<Text size="8pt">Saving</Text>:<></>}

                                    {theComment.prediction && mode==full_mode && (
                                        <MiniBadge>
                                            <Tip content={
                                                <Box>
    
                                                <Text size="8pt">handling: {theComment.prediction.handling }</Text>
                                                <Text size="8pt">predicted: {theComment.prediction?.predicted?.map(p=>p.label + " " + Math.round(p.score * 100) + "% ").join(", ") }</Text>
                                                </Box>
                                            }>
                                            {theComment.prediction?.handling == "manual" ? (

                                                <Text size="8pt">🤖...? -&gt; 👩🏻‍💻</Text>

                                            ) : (
                                                theComment.prediction?.handling == "model-review" ? (


                                                    <Text size="8pt">

                                                        🤖
                                                        {theComment.prediction?.predicted ? (theComment.prediction?.predicted.map(p => p.label + " " + Math.round(p.score * 100) + "% ")) : "..."}
                                                        ...? -&gt; (👩🏻‍💻)
                                                    </Text>
                                                ) : (
                                                    <Text size="8pt">

                                                        🤖
                                                        {theComment.prediction?.predicted ? (theComment.prediction?.predicted.map(p => p.label + " " + Math.round(p.score * 100) + "% ")) : "..."}
                                                    </Text>
                                                )
                                            )}
                                        </Tip>
                                       { onExplainPredictionsClick && 
                                        <ExpandingButton 
                                            pad="0px 0px"
                                            border={false}
                                            background="light-1"
                                            onClick={()=>onExplainPredictionsClick(theComment.text)}
                                            expandedContent={
                                                <Box round margin="0px" pad="0px 5px" background="brand" >
                                                <Text  size='xsmall'>Explain predictions</Text>
                                                </Box>
                                            } collapsedContent={
                                                <Box  margin="1px" pad="0px 2px"  >
                                                <CircleInformation size="small"/>

                                                </Box>
                                            }/>}
                                        </MiniBadge>
                                    )}

                                    {!theComment.is_reviewed && !theComment.ignored && theComment.author.id != theComment.social_account ? (
                                        <MiniBadge>

                                            <GiSandsOfTime size="12px" />
                                            <Text size="8pt">Pending</Text>
                                        </MiniBadge>

                                    ) : (

                                        <></>

                                    )}
                                    {theComment.human_review && (
                                        <MiniBadge>
                                            <Text size="8pt">👩🏻‍💻 {theComment.human_review }</Text>
                                            
                                        </MiniBadge>
                                    )}
                                    <MiniBadge>
                                       <Text size="8pt">{theComment.human_review ? (" 👩🏻‍💻") : (" 🤖 ")}</Text>
                                        <Text size="8pt">{theComment.reaction?.action_id || "no action"} </Text>
                                    </MiniBadge>

                                    <Text></Text>
                                </Box>
                            )):(
                                <Box  focusIndicator={false}  onClick={(e)=>label_settings?modifyLabel(e.target):undefined}>
                                <MiniBadge>
                                    {theComment.human_review ?(
                                        <Text size="8pt">{label_settings?label_settings[theComment.human_review]?.icon:""} {" "+theComment.human_review }</Text>
                                    ):(
                                        <Text size="8pt">{theComment.prediction?.predicted?.map(p=>  (label_settings?label_settings[p.label ]?.icon :"") + " "+ p.label + " " + Math.round(p.score * 100) + "% ").join(", ") }</Text>
                                    )}
                                    
                                    <Text size="8pt">  -&gt; {theComment.reaction?.action_id || "no action"} </Text>
                                </MiniBadge>
                                </Box>
                            ) }
                        </Box>
                    </Box>
                </Box>
            </Box>


        </Box>
    )
}