import { useState, useEffect, useRef } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar, Tab, Tabs, Select } from 'grommet';
import {BarChart, FormPrevious, History, Play,Refresh,Return,Unlink, User, UserSettings } from "grommet-icons"


import getApi from "../Middleware/apiService"




import { useNavigate, useSearchParams } from 'react-router-dom';
import { RoundSmallButton } from '../components/roundSmallButton';
import {authorizeAccessInstagram, authorizeAccessFacebook,revokeAll,revokeFacebook,revokeInstagram, initFbSdk} from "../Middleware/fabebookService"
import { PlatformIcon } from '../components/PlatformIcon';
import { getIcon } from '../components/IconCatalogue';
import { FaArchive, FaCheck } from 'react-icons/fa';
import { MdAssistant, MdEmail, MdOutlineDeleteForever } from 'react-icons/md';
import { LinkedAccountBox } from '../components/LinkedAccountBox/LinkedAccountBox';
import { AutoUI } from '../components/AutoUI/AutoUI';
import { CommentBox } from '../components/CommentBox/CommentBox';
import { useAuth } from '../auth/AuthProvider';
import { Comments } from '../components/Comments/comments';
import { BiCommentDetail, BiStats } from 'react-icons/bi';

import { Statistics } from '../components/Statistics/statistics';
import { InviteToConnectDialog } from '../components/InviteToConnectDialog/InviteToConnectDialog';
import { ConfirmDialog } from '../components/ConfirmDialog/ConfirmDialog';
import { ConfirmAccountsDialog } from '../components/ConfirmAccountsDialog/ConfirmAccountsDialog';
import { setDoc } from 'firebase/firestore';
import { AuthorAnalytics } from '../components/AuthorAnalytics/authorAnalytics';
import { Posts } from '../components/Posts/posts';
import { ExpandingButton } from '../components/ExpandingButton/expandingButton';



const LinkYoutubeChannelButton=({onSuccess})=>{
    const [editMode,setEditMode] = useState();
    const [channelIdStr,setChannelIdStr] = useState("");
    return (<Box pad="5px" flex="shrink" border round direction='row' gap="small">
        {editMode&&(
            <Box   direction='row' gap="small">
            <input type="text" style={{height:"10pt"}} value={channelIdStr} onChange={e=>setChannelIdStr(e.target.value)}/>
            
            <Button plain icon={<FaCheck/>} onClick={()=>{
                setEditMode(false);
                getApi().youtubeLinkChannel(channelIdStr).then(()=>{
                    onSuccess()
                    setEditMode(false);
                    setChannelIdStr("");
                })
            }
            }/>
            </Box>
        )}
        <Button icon={!editMode ? getIcon("more"):getIcon("close")} size="small" plain onClick={()=>setEditMode(!editMode)}/></Box> )
}


export const SettingsPage = ({mode})=>{

    
   
    const [activeProejcts, setActiveProjects] =useState();
    const [refreshingAccounts, setRefreshingAccounts] =useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState()
    const { currentUser,logout } = useAuth();
    const currentUserRef=useRef(currentUser)
    currentUserRef.current = currentUser;

    const [ settings,setSettings ] = useState();
    const [accounts, setAccounts] =useState();
    
    const [project_id, setProjectId] =useState();

    const [queryParams, setQueryParams] =useSearchParams(); 
    const [projectInfo, setProjectInfo]=useState()

    useEffect(()=>{
        if(mode=="client"||project_id){

            getApi().getSettingsInfo(project_id).then((infoData)=>{
                setProjectInfo(infoData)
            })
        }
        
    },[project_id])

    useEffect(()=> 

        getApi().getActiveProjects().then(data=>setActiveProjects(data))
    ,[])
    
    const [error, setError] =useState();
    
    
    const navigate = useNavigate();

    // const [latestComments,setLatestComments] = useState();
    // function refreshLatest(){
    //     setLatestComments(null);
    //     getApi().getLatest().then(data=>setLatestComments(data||[]));
    // }

    // useEffect(()=>{
    //     initFbSdk();
    // } ,[])



    useEffect(()=>{
        if(queryParams.get("project_id")){
            setProjectId(queryParams.get("project_id"))
        }
        else{
            setProjectId(null)
        }
    },[queryParams])


    useEffect(()=>{
        if (currentUser && (project_id||mode=="client")){
            
            getSettings();
        }
        else if(!currentUser && (project_id|| mode=="client") ){
            setTimeout(()=>{
                if (currentUserRef.current===undefined && !queryParams.get("redirected")){
                    let loginQuery = new URLSearchParams()
                    let queryParamsCopy= new URLSearchParams(queryParams)
                    if (queryParamsCopy.get("access_token")){
                        queryParamsCopy.delete("access_token")
                        loginQuery.set(queryParamsCopy.get("access_token"))
                    }
                    
                    loginQuery.set("redirect",encodeURIComponent(window.location.origin + window.location.pathname + "?" +queryParamsCopy ))

                    navigate(`/login?${loginQuery}`)
                }
            },2000)
        }
    },[currentUser, project_id]);

    function getSettings(){
        if (project_id===undefined) return;

        if (project_id && mode!="client"){
            setRefreshingAccounts(true)
            getApi().getSettings(project_id)
            .then(data=>{
                setSettings(data);
                setRefreshingAccounts(false)
            })
            .catch((err)=>{
                setRefreshingAccounts(false)
                if ((err.response && err.response.data && err.response.data.code)==1){
                    setSettings("NOT_ACTIVATED")
                }
                else{
                    setError(err.message)
                }
            })
        }
        else{
            getAccounts()
        }
    }

    function getAccounts(){

        
        setRefreshingAccounts(true)
        getApi().getClientAccounts()
        .then(data=>{
            setAccounts(data);
            setRefreshingAccounts(false)
        })
        .catch((err)=>{
            setRefreshingAccounts(false)
            if ((err.response && err.response.data && err.response.data.code)==1){
                setError("NOT_ACTIVATED")
            }
            else{
                setError(err.message)
            }
        })
   
}

    function revoke(account, deleteData){
        // if (account.platform=="youtube"){
        //     getApi().youtubeAuthRevoke(account.id,deleteData).then((val)=>checkAccount()).catch(err=>setError(err.toString()))
        // }
        // else 
        if (account.platform==="instagram"){
            return getApi().accountDelete(account.platform, account.original_key, deleteData).then(()=>getSettings()).catch(err=>setError(err.toString()))
        }
        if (account.platform==="facebook"  ){
            return getApi().accountDelete(account.platform, account.original_key, deleteData).then(()=>getSettings()).catch(err=>setError(err.toString()))
        }
        
    }


    

    function linkYoutube(){
        
        getApi().youtubeAuthUrlGet(window.location.href).then((val)=>{
            window.location.href = val.data.authorization_url
        }
        ).catch(err=>setError(err.toString()))
    }

    function linkFacebook(){
        authorizeAccessFacebook(project_id,(reponse)=>{
            setRefreshingAccounts(true)
            getApi().linkAccount("facebook",reponse.accessToken, project_id, true).then((secondResponse)=>{

                setDialog(<ConfirmAccountsDialog 
                    accountsToConfirm={secondResponse.new_accounts} 
                    accountsInOtherProjects={secondResponse.others}
                    errors={secondResponse.errors}
                    onCancel={()=>{
                        setRefreshingAccounts(false)
                        setDialog()
                    }}
                    onConfirm={(accounts)=>{
                        setDialog()
                        getApi().linkAccount("facebook",reponse.accessToken, project_id, false, accounts)
                        .then(()=>{
                            getSettings()
                        }
                        )
                    }}
                    />)
            }
                
            ).catch(err=>setError(err.message))
       })
    }

    

    function linkInstagram(){
        authorizeAccessInstagram(project_id,(reponse)=>{
            setRefreshingAccounts(true)
            getApi().linkAccount("instagram",reponse.accessToken, project_id, true).then((secondResponse)=>{

                setDialog(<ConfirmAccountsDialog 
                    accountsToConfirm={secondResponse.new_accounts} 
                    accountsInOtherProjects={secondResponse.others} 
                    errors={secondResponse.errors}
                    onCancel={()=>{
                        setRefreshingAccounts(false)
                        setDialog()
                    }}
                    onConfirm={(accounts)=>{
                        setDialog()
                        getApi().linkAccount("instagram",reponse.accessToken, project_id, false, accounts)
                        .then(()=>{
                            getSettings()
                        }
                        )
                    }}
                    />)
            }
                
            ).catch(err=>setError(err.message))
        })
     }
   


    function commitUnsavedChanges(){
        getApi().patchSettings(project_id, {settings:unsavedChanges}).then((updated)=>{
            let newSettings = {...settings}
            newSettings.settings=updated?.settings
            setSettings(newSettings)
            setUnsavedChanges(null)
            
        })

    }
    
    function discardUnsavedChanges(){
        setUnsavedChanges(null)
        settings.settings=Object.assign({},settings.settings) //enforce refresh original value in AutoUI
    }

    function explainPrediction(text){
        let nodeUrl = `https://app.labelator.io/serving/test/${settings.settings.node_name}`
        window.open(nodeUrl+"?prompt="+encodeURIComponent(text), "_blank")
    }



     useEffect(()=>{
        if (settings){
            setAccounts(settings.accounts)
        }
    },[settings])

    useEffect(()=>{
        if (accounts?.length){
            setSelectedAccounts(accounts.map(a=>a.original_key))
        }
    },[accounts])

    const [statsDetailsMetric,setStatsDetailsMetric] =useState()
    const [dialog,setDialog]  =useState()
    const [selectedAccounts, setSelectedAccounts] = useState();

    return currentUser && (accounts) ? (
    <Box fill  >
        
        <Box pad="20px 0px 0px 20px" gap="small">
        {dialog}

          {mode=="client"?(
            <Box justify='end' pad="10px" direction='row' align='center'>
            <Text size="small">{currentUser.displayName||currentUser.email} </Text>
                <ExpandingButton
                onClick={()=>logout()}
                border={false}
                collapsedContent={  <Box direction='row' background="brand" round pad="3px 10px" align='center' justify='center' gap='small'>
                <User size="medium"/>
                
            </Box>}
                expandedContent={
                <Box direction='row' background="brand" round pad="3px 10px" align='center' justify='center'>
                    <User size="medium"/>
                    <Text size="small">Logout</Text>
                </Box>
                }
                />
            </Box>
          ):(
            <></>
          )}  
        

        <Box fill>
            <Tabs>
            <Tab title="Accounts and settings" icon={<UserSettings/>}>
            <Box pad="small" >
           
           <Box direction='row' justify='between'>
            <Text weight={900}>Linked accounts</Text>
            {mode!="client" && activeProejcts && activeProejcts.length&&
            <Select options={activeProejcts.filter(p=>p.id!==project_id)} labelKey="name" placeholder="Other connected projects" size="small" style={{padding:"0px 5px"}} onChange={({option}) => {
                if (option){
                     let navigate_to =   "/integration/social?project_id=" + option.id
                     console.log(navigate_to)
                     if (window.parent){
                         window.parent.postMessage({navigateTo:navigate_to}, '*');
                     }
                     else{
                        setQueryParams({project_id:option.id})
                        
                     }
                }
            }}/>}
            
           </Box>
                <Box direction='row' wrap  gap="small">
                
                    {!refreshingAccounts? (
                    accounts?.length?
                        accounts.filter(t=>t.active!==false).map((account,i)=>(
                            <LinkedAccountBox key={i} account={account} onRevoke={revoke}/>
                        )):
                        (
                            <Box align='center' justify='center' pad="20px" width="100%">
                                <Box background="rgb(240,240,240)" round pad="0px 20px">
                                    <Text weight={200}>...no accounts linked yet...</Text>
                                </Box>
                            </Box>
                        )
                    ):(
                        <Box align='center' justify='center' pad="20px">
                        <Spinner/>
                        </Box>
                    )}
                </Box>
            </Box>
            <Box gap="small" align='center'>
            {/* <Box direction='row' gap="small">
                <Button plain label="Link youtube" icon={<PlatformIcon platformName="youtube"/>} onClick={linkYoutube}/> 
                { userStatus && userStatus.appUser?.active_plan=='testUser' && <LinkYoutubeChannelButton onSuccess={()=>checkAccount()}/>}
            </Box> */}

            {/* <Box direction='row'><Button plain label="Add instagram" icon={<PlatformIcon platformName="instagram"/>} onClick={linkInstagram}/></Box> */}
            <Box align='start' gap="xsmall">
                <Box direction='row'><Button plain label="Connect with Facebook" icon={<PlatformIcon platformName="facebook"/>} onClick={linkFacebook}/></Box>
                <Box direction='row'><Button plain label="Connect with Instagram" icon={<PlatformIcon platformName="instagram"/>} onClick={linkInstagram}/></Box>
                {mode!="client"?(<Box direction='row'><Button plain label={<Text size='small'>Create 'invite to connect' link</Text>} icon={<MdEmail size="20px"/>} onClick={()=>setDialog(<InviteToConnectDialog project_id={project_id} onClose={()=>setDialog() }/>)}/></Box>):<></>}
            </Box>
            
            </Box>
            {settings ?(
                <Box>
                    <Text weight={900}>Default settings</Text>
                    <Box>

                        <AutoUI 
                            value={settings.settings} 
                            ui_hints={settings["$ui_hints"]}
                            onValueChange={(value)=>{
                                setUnsavedChanges(value)
                            }}
                            />
                        {unsavedChanges&&(
                            <Box pad="small" gap="5px" direction='row'> <Button primary size="small" label="Save" onClick={commitUnsavedChanges}/>   <Button secondary  size="small"  label="Cancel" onClick={discardUnsavedChanges}/> </Box>
                        )}
                    </Box>
                </Box>
                )
                :
                (<></>)
            }
         
            </Tab>
            <Tab title="Latest posts" icon={<MdAssistant size="25px"/>}  >
                <Box overflow="auto"  width="100vw" >

                <Box direction='row' margin="10px 0px"  >

                    <Text >Latest posts</Text>
                </Box>
                <Posts project_id={project_id} allAccounts={accounts} onExplainPredictionsClick={settings && settings.settings.node_name?((text)=>explainPrediction(text)):undefined } 
                    mode={mode}
                    selectedAccounts={selectedAccounts}
                    projectInfo={projectInfo}
                    onSelectedAccountsChange={setSelectedAccounts}
                    />
                </Box>
            </Tab>
            <Tab title="Latest comments" icon={<BiCommentDetail size="25px"/>}  >
                <Box overflow="auto"  width="100vw" >

                <Box direction='row' margin="10px 0px"  >

                    <Text >Latest comments</Text>
                </Box>
                
                <Comments project_id={project_id} 
                    mode={mode}
                    cacheKey="latestComments"
                    projectInfo={projectInfo}
                    allAccounts={accounts} onExplainPredictionsClick={settings && settings.settings.node_name?((text)=>explainPrediction(text)):undefined } 
                    selectedAccounts={selectedAccounts}
                    onSelectedAccountsChange={setSelectedAccounts}
                    />
                </Box>
            </Tab>
            <Tab title="Statistics" icon={<BarChart/>}>
                {!statsDetailsMetric?(
                    <Box  margin="10px 0px">

                        <Text >Statistics </Text>
                        <Statistics project_id={project_id} onOpenWidgetDetail={((params)=>setStatsDetailsMetric(params))} 
                        projectInfo={projectInfo}
                        allAccounts={accounts}
                        metrics={mode=="client"?["total","hidden","replied","deleted"]:undefined}
                        selectedAccounts={selectedAccounts}
                        onSelectedAccountsChange={setSelectedAccounts}
                        />
                        
                    </Box>
                ):(
                    <Box  margin="10px 0px" align='start'>

                        <Box direction='row' onClick={()=>setStatsDetailsMetric()} pad="0px 20px" round background="lightgray">
                            <FormPrevious/>
                            <Text>Back</Text>
                        </Box>
                        <Box direction='row' >
                            {/* <Text size="15pt" weight={700} > {statsDetailsMetric.options?.label + " Latest comments"}  </Text> */}
                        </Box>
                        <Comments mode={mode} project_id={project_id} 
                        projectInfo={projectInfo}
                        since={statsDetailsMetric.since}
                        until={statsDetailsMetric.until}
                         filter={statsDetailsMetric.metric} 
                         allAccounts={accounts} 
                         author_id={statsDetailsMetric.author}
                         onExplainPredictionsClick={(settings && settings.settings.node_name)?((text)=>explainPrediction(text)):undefined }
                        selectedAccounts={selectedAccounts}
                        
                        onSelectedAccountsChange={setSelectedAccounts}
                        />
                    </Box>
                )}
                
            </Tab>
            </Tabs>
        </Box>
       {error?
        (<Text color="red">{error}</Text>)
        :
        (<></>)
       }
       
       
        </Box>
    </Box>
    ):
    (
       <Box fill background="white" align='center' justify='center' pad="100px">
       {(project_id || mode=="client")?(
        <Box  align='center' justify='center'>

            Loading
            <Spinner size="large"/>
            
            <Text>Welcome</Text>
            <Text>{currentUser?.email}</Text>
        </Box>
       ):(
        <Box align='center' gap="small">
            <Text>No project defined</Text>
            <Text>{activeProejcts?(
                (activeProejcts && activeProejcts.length)?
                <Select options={activeProejcts.filter(p=>p.id!==project_id)} labelKey="name" placeholder="Select project" size="small" style={{padding:"0px 5px"}} onChange={({option}) => {
                    if (option){
                        let navigate_to =   "/integration/social?project_id=" + option.id
                        console.log(navigate_to)
                        if (window.self !== window.top){
                            window.parent.postMessage({navigateTo:navigate_to}, '*');
                        }
                        else{
                            setQueryParams({project_id:option.id})
                            
                        }
                    }
                }}/>:(<Text  size="xsmall">No projects availible. To allow a this module, please enable it for project from labelator.io.</Text>)

            ):(  activeProejcts ? <Text size="small"> Plugin not activated for any proejct </Text> : "..."  )}</Text>
            
            
            <Text size="small"> (logged in as <Text size="small" weight={800}>{currentUser?.email}</Text>) </Text>
            <Button size="small" label="It's not me" onClick={()=>{
                logout()
                navigate("/login?force=true")
                }}/>
            
            </Box>
            
       )}
       </Box>

    );
  
}