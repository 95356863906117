import { useState, useEffect, useRef } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar, Tip } from 'grommet';
import {FormClose, Play,Refresh,Unlink } from "grommet-icons"
import { UserImpl } from '@firebase/auth/internal';
import { 
     User,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    GoogleAuthProvider, FacebookAuthProvider, signInWithPopup 
} from "firebase/auth";

import getApi from "../Middleware/apiService"

import {useAuth} from "../auth/AuthProvider"
import {auth} from "../auth/firebase"
import { signInWithCustomToken } from "firebase/auth";
import { useNavigate, useSearchParams } from 'react-router-dom';

import {authorizeAccessInstagram, authorizeAccessFacebook,revokeAll,revokeFacebook,revokeInstagram, initFbSdk} from "../Middleware/fabebookService"

import { getIcon } from '../components/IconCatalogue';
import { FaArchive, FaCheck } from 'react-icons/fa';


const LinkYoutubeChannelButton=({onSuccess})=>{
    const [editMode,setEditMode] = useState();
    const [channelIdStr,setChannelIdStr] = useState("");
    return (<Box pad="5px" flex="shrink" border round direction='row' gap="small">
        {editMode&&(
            <Box   direction='row' gap="small">
            <input type="text" style={{height:"10pt"}} value={channelIdStr} onChange={e=>setChannelIdStr(e.target.value)}/>
            
            <Button plain icon={<FaCheck/>} onClick={()=>{
                setEditMode(false);
                getApi().youtubeLinkChannel(channelIdStr).then(()=>{
                    onSuccess()
                    setEditMode(false);
                    setChannelIdStr("");
                })
            }
            }/>
            </Box>
        )}
        <Button icon={!editMode ? getIcon("more"):getIcon("close")} size="small" plain onClick={()=>setEditMode(!editMode)}/></Box> )
}


export const LoginPage = ()=>{

    const defaultState={login:"", password:"", confirmPassword:""};
   
    const [loginInfo, setLoginInfo] =useState(defaultState);
    const [register, setRegister] =useState(false);
    const [refreshingAccounts, setRefreshingAccounts] =useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState()
    const { currentUser, setCurrentUser } = useAuth();
    const currentUserRef=useRef(currentUser)
    currentUserRef.current = currentUser;

    const [ settings,setSettings ] = useState();
    const [ activationCode,setActivationCode] = useState();
    const [project_id, setProjectId] =useState();
    const [showLogin, setShowLogin] =useState();

    const [searchParams, setSearchParams] =useSearchParams(); 
    
    useEffect(()=>{
        if (searchParams.get("login")){

            window.parent.postMessage({getUser:true}, '*');
            
        }
        if( searchParams.get("project_id")){

            setProjectId( searchParams.get("project_id"))
            console.log(searchParams.get("project_id"))
        }
    },[searchParams])
    
    useEffect(()=>{
        window.addEventListener('message', event => {
            // IMPORTANT: check the origin of the data!
            if (event.origin.includes("labelator")||event.origin.includes("://localhost")) {
                console.log("recieved!")
                console.log(event.data)
                // The data was sent from your site.
                // Data sent with postMessage is stored in event.data:
                
                console.log(event.data);
                if (event.data.user){
                    console.log("accepted!")
                    let userData=JSON.parse(event.data.user);
                    
                 
                    const user  = UserImpl._fromJSON(auth, userData)
                    auth.updateCurrentUser(user);
                    
                    setProjectId( event.data.project_id)
                }
              
                
            } else {
                
                // The data was NOT sent from your site!
                console.error("Invalid origina triggered navigate event "+event.origin)
                return;
            }
        });
    },[])
    
    const [error, setError] =useState();
    const [dialog, setDialog] =useState();
    
    
    const navigate = useNavigate();

    // const [latestComments,setLatestComments] = useState();
    // function refreshLatest(){
    //     setLatestComments(null);
    //     getApi().getLatest().then(data=>setLatestComments(data||[]));
    // }
    // useEffect(()=>{
    //     refreshLatest();
    // },[])
    // useEffect(()=>{
    //     initFbSdk();
    // } ,[])





    useEffect(()=>{
        if (currentUser && !searchParams.get("force")){
            if (searchParams.get("redirect")){
                window.location.href = decodeURIComponent(searchParams.get("redirect"))+"&redirected=1"
            }
            else if (searchParams.get("project_id")){
                console.log(searchParams.get("project_id"))
                window.location.href =  ("/settings?project_id="+searchParams.get("project_id"))
            }
            else{
                setTimeout(
                    ()=>window.location.href =  ("/client"),3000
                )
            }
        }else{
            if (!searchParams.get("force") && !searchParams.get("invite_id")){
                
                setTimeout(()=>{
                    if (currentUserRef.current==undefined){
                        setShowLogin(true)
                    }
                },5000)
            }
            else{
                setShowLogin(true)
            }
        }
    },[currentUser, project_id, searchParams]);

    function checkAccount(){

        if (project_id){
            setRefreshingAccounts(true)
            getApi().getSettings(project_id)
            .then(data=>{
                setSettings(data);
                setRefreshingAccounts(false)
            })
            .catch((err)=>{
                setRefreshingAccounts(false)
                if ((err.response && err.response.data && err.response.data.code)==1){
                    setSettings("NOT_ACTIVATED")
                }
                else{
                    setError(err.message)
                }
            })
        }
        else{
            navigate("404")
        }
    }

    function revoke(account, deleteData){
        // if (account.platform=="youtube"){
        //     getApi().youtubeAuthRevoke(account.id,deleteData).then((val)=>checkAccount()).catch(err=>setError(err.toString()))
        // }
        // else 
        if (account.platform==="instagram"){
            return revokeInstagram(account).then(()=>getApi().accountDelete(account.platform, account.original_key, deleteData)).then((val)=>checkAccount()).catch(err=>setError(err.toString()))
        }
        if (account.platform==="facebook"  ){
            return  revokeFacebook(account).then(()=>getApi().accountDelete(account.platform, account.original_key, deleteData)).then((val)=>checkAccount()).catch(err=>setError(err.toString()))
        }
        
    }

    const validatePassword = () => {
        let isValid = true
        if (loginInfo.password !== '' && loginInfo.confirmPassword !== ''){
            if (loginInfo.password !== loginInfo.confirmPassword) {
            isValid = false
            setError('Passwords does not match')
            }
        }
        return isValid
    }
    function tryToActivateWithInvite(){
        if (localStorage.getItem("invite_id")){
            let invite_id=localStorage.getItem("invite_id")
            let invite_check=localStorage.getItem("invite_check")
            localStorage.removeItem("invite_id")
            localStorage.removeItem("invite_check")
            setSearchParams({});
            return getApi().activateInvite(invite_id,invite_check).catch(e=>{
                setError(e.message)
            })
        }
        return Promise.resolve()
    }


    const registerOrLogin = e => {
        e.preventDefault()
        setError(null);
        if(validatePassword()) {
            if (register){
                // Create a new user with email and password using firebase
                let invite_id = searchParams.get("invite_id")
                let invite_check = searchParams.get("invite_check")
                createUserWithEmailAndPassword(auth, inviteEmail|| loginInfo.login, loginInfo.password)
                .then(()=>{
                    setSearchParams({})
                    if(invite_id){

                        getApi().activateInvite(invite_id,invite_check, inviteEmail|| loginInfo.login)
                        .then(()=>{
                            window.location.reload()
                        })
                        .catch(e=>{
                            setError(e.message)
                        })
                    }
                }
                )
                .catch(err => {
                    if (err.code==='auth/email-already-in-use'){
                        signInWithEmailAndPassword(auth, inviteEmail|| loginInfo.login, loginInfo.password)
                        .catch(ex=>{
                            setError("User already exist. Trying to login failed: "+ex.message)

                        })
                    }
                    setError(err.message)

                })
            }
            else{
                signInWithEmailAndPassword(auth,loginInfo.login, loginInfo.password).then(()=>{
                    window.location.href="/client"
                })
                .catch(err => setError(err.message))
            }
        }
        //setLoginInfo(defaultState);
    }



    function loginWithGoogle(){
        setError(null);
        const googleProvider = new GoogleAuthProvider();
        googleProvider.setCustomParameters({
            login_hint:inviteEmail||loginInfo.email
        })
        signInWithPopup(auth, googleProvider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            let credential = GoogleAuthProvider.credentialFromResult(result);
            localStorage.setItem('user', JSON.stringify(result.user));
            localStorage.setItem('googleCredentials',  JSON.stringify(credential));

            tryToActivateWithInvite()

            // ...
        }).catch((error) => {
            setError(error.message)
        });
    }

    

    function logout(){
        // setCurrentUser(null);
        // localStorage.setItem('user', null);
        auth.signOut().then(function() {
            
          }).catch(function(error) {
            setError(error.message)
          });
    }

    
    

    const [inviteEmail, setInviteEmail]=useState(undefined);
    useEffect(()=>{
        if (searchParams.has("invite_id") && searchParams.has("invite_check") ){
            localStorage.setItem("invite_id",searchParams.get("invite_id"))
            localStorage.setItem("invite_check",searchParams.get("invite_check"))
            let _inviteEmail=searchParams.get("invite_id").split(":")[1];
            setInviteEmail(_inviteEmail)
            setLoginInfo({email:_inviteEmail,password:"",confirmPassword:""})
            setRegister(true);
        }
    },[searchParams]);
  

    return currentUser && settings ? (
    <Box fill  align="center" background="white">
        
        <Box pad="large" gap="small" align="center" >
         <Box direction='row' justify='center' align='center'>
        <Text>Signed as {currentUser.displayName || currentUser.email}</Text>
        <Button secondary label="Logout" onClick={()=>logout()} pad="medium" margin={{ horizontal: 'xsmall' }}/>

         </Box>

       {error?
        (<Text color="red">{error}</Text>)
        :
        (<></>)
       }
       
       
        </Box>
    </Box>
    ):
    (showLogin?(
        
        <Box fill justify="center" align="center" pad="xlarge" gap="medium">
            
            <Box align="center" pad='medium' width="medium"  round
                border={{ color: 'brand', size: 'medium' }} background={{ color: "light-2", opacity: "strong" }}>
                  <Form 
                    onSubmit={registerOrLogin} 
                    value={loginInfo}
                    onChange={newVal=> setLoginInfo(newVal)}
                    onReset={()=>setLoginInfo(defaultState)}
                >
                    <Box gap="small"  flex="grow">
                       
                            {!inviteEmail? 
                             <FormField name="login" label="Login" validate={{ regexp: /^[a-z|A-Z|-|_|\d|\.]+@[a-z|A-Z|-|_|\d]+\.[a-z|A-Z]+/i }}>
                            <TextInput name="login"  /> 
                            </FormField>
                            : 
                            <Box pad="0px 10px" align='start'>
                                <Text>Login</Text>
                            <Box  direction='row' round border background="rgba(0,0,0,0.1)" pad="0px 10px" margin="5px" align='center'>

                                <Text>{inviteEmail}</Text>
                                <Tip content="Removing Email login field will remove the invite code from sign up process. The invite link will still work if you'll change your mind later. ">
                                <Box onClick={()=>{
                                        setInviteEmail(undefined)
                                        localStorage.removeItem("invite_id")
                                        localStorage.removeItem("invite_check")
                                    }
                                }><FormClose/></Box>
                                </Tip>
                            </Box>
                            </Box>
                            }
                        
                        <FormField name="password" label="Password" >
                            <TextInput name="password" type="password" />
                        </FormField>
                        
                        {register && (
                            <FormField name="confirmPassword" label="Confirm password" >
                                <TextInput name="confirmPassword" type="password" />
                            </FormField>
                        )}
                        
                        <Box margin={{ top: "medium" }}>
                            {register ?
                                (
                                    <Box  gap="medium"  align="center">
                                    <Button type="submit" primary label="Sign up" pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button secondary label="Sign Up with google" onClick={()=>loginWithGoogle()} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button plain label="Back to login" onClick={()=>setRegister(false)} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    </Box>
                                )
                                :
                                (
                                    <Box  gap="medium" align="center" >
                                    <Button type="submit" primary label="Login"  pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button secondary label="Login with google" onClick={()=>loginWithGoogle()} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    <Button plain label={<Text style={{textDecoration:"underline"}}>Create a new account</Text>} onClick={()=>setRegister(true)} pad="medium" margin={{ horizontal: 'xsmall' }}/>
                                    </Box>
                                )
                                
                            }
                        </Box>
                    
                        <Text color="red">{error}</Text>
                    </Box>
                </Form>

             
  		  	</Box>
        </Box>
    ):(
        <Box fill align='center' justify='center' pad="20%">
            {project_id?
            (
                <Spinner size="large"/>
            ):(
                <>
                <Text>Welcome</Text>
                <Text>{currentUser?.email || "not loged in"}</Text>
                <Text></Text>
                </>

            )}
            </Box>
    )
    );
  
}