import { Avatar, Box, Spinner, Text, Tip } from "grommet"
import { Alert, CircleInformation, User } from "grommet-icons"
import { GrHide } from "react-icons/gr"
import { GiSandsOfTime } from "react-icons/gi"
import moment from 'moment'

import "./PostBox.css"
import { FaUserCircle } from "react-icons/fa"
import { useInView } from 'react-intersection-observer';
import { MdError } from "react-icons/md"
import { useEffect, useMemo, useState } from "react"
import { Comments } from "../components/Comments/comments"
import getApi from "../Middleware/apiService"

const MiniBadge = ({ children }) => {
    return (
        <Box direction="row" border round flex="shrink" pad="2px 5px" align="center" justify="center" gap="2px">
            {children}
        </Box>
    )
}



export const PostBox = ({ post, mode, account, projectInfo }) => {
    const { ref, inView, entry } = useInView({
        /* Optional options */
        threshold: 0,
    });

    let statsLoading=false;
    const [stats,setStats]=useState()
    useEffect(()=>{
        if (inView && !statsLoading&&!stats ){
            statsLoading=true;
            getApi().get_post_stats(post.platform, post.original_key).then(stats=>{
                setStats(stats)
                statsLoading=false;
            })
        }
    },[inView])

    const label_settings=useMemo(()=>{
        if (projectInfo){

            if (projectInfo.labels_settings)
            return projectInfo.labels_settings;
            else{
                let val={}
                projectInfo.labels.forEach(l=>val[l]=null)
                return val
            }
        }

    },[projectInfo])

    const [showComments,setShowComments] =useState()
    return (
        <Box ref={ref} width="100%" margin="5px 0px" pad="xsmall" border round="xsmall">

            <Box  >
                <Box direction="row"  justify="start" align="start" >
                    {account && (
                        <Box pad="0px 5px" flex={false}>
                            {/* <FaUserCircle size="30px" color="lightgray" /> */}
                            <Box round="25px" style={{overflow:"hidden"}}>

                                <img src={account.avatar_url} width="30px" height="30px"/>
                            </Box>
                        </Box>

                    )}
                    <Box >
                        <Box direction="row" gap="5px" >

                            <Text size="8pt">{account?.name} </Text>
                            <Box round background="rgb(240,240,240)" pad="2px 5px">

                                <Tip content={<Text size="10pt"> {moment(post.timestamp).format("LLL")}</Text>}><Text size="8pt">{moment(post.posted_date).fromNow()}</Text></Tip>
                            </Box>
                            <Tip content={<Text size="8pt">{`Id: ${post._id} (Copy)`}</Text>}>
                                <Box focusIndicator={false} margin="1px" pad="0px 2px" onClick={()=>navigator.clipboard.writeText(post._id)} >
                                        <CircleInformation size="small"/>
                                </Box>
                            </Tip>
                        

                        </Box>

                        <Box  align="start">
                            <Box className= "posttBox" onClick={()=>window.open(post.permalink ,"_blank")} >
                            <Text size="small" color={post.title ? undefined:"gray"}>{post.title  ||"[no text]"}</Text>
                            </Box>
                          
                        </Box>
                    </Box>
                </Box>
                        <Box height={showComments?undefined:"35px"} justify="end" alignSelf="stretch">
                            {!stats?(
                                <Box pad="0px 20px">
                                    <Spinner size="2px"/>
                                </Box>
                            ):(
                                stats.total?(
                                <Box direction="row" gap="2px" justify="between" width="100%" >
                                    
                                {showComments?(
                                    <Box>
                                        <Box direction="row" focusIndicator={false} onClick={()=>setShowComments(false)}><Text color="blue"  size="small">Hide comments</Text> ({stats.total})</Box>
                                        
                                    </Box>
                                ):(
                                    <Box direction="row" focusIndicator={false} onClick={()=>setShowComments(true)}><Text color="blue"  size="small">Show comments</Text>  ({stats.total})</Box>
                                )}
                                <Box direction="row" gap="2px" alignSelf="start">
                                        
                                        
                                        {Object.keys(stats.by_label||{}).map(k=>(
                                            <Box key={k} border round="small" pad="0px 5px">

                                                <Text  size="small" > {(label_settings?label_settings[k]?.icon:"")} {(k)}:{stats.by_label[k]}</Text>
                                            </Box>
                                        ))}
                                            {/* {Object.keys(stats.by_label||{}).map(k=>(<Text  size="small" key={k}>{stats.by_label[k]} {label_settings?label_settings[k]?.icon:""}  {{k}}</Text>))} */}
                                        
                                        
                                    </Box>
                                </Box>
                                ):(<Text color="gray" size="small">No comments</Text>)
                            )}
                            </Box>
            </Box>
                            {showComments?(
                                <Box 
                                    //style={{maxHeight:"75vh", overflow:"auto"}} 
                                    margin="10px">
                                    <Comments cacheKey={"post_comments"+post._id} mode={mode} projectInfo={projectInfo} owner_post_key={post.original_key} selectedAccounts={[post.social_account]} allAccounts={[account]} />      
                                </Box>
                            ):<></>}


        </Box>
    )
}