
import { Box, Button, CheckBox, Select, Spinner, Text, TextInput } from "grommet";
import { Close, Edit } from "grommet-icons";
import { useEffect, useMemo, useState } from "react";
import { TipIfContent } from "../TipIfContent";
import "./AutoUI.css"



const FieldValue = ({ fieldValue, size, field_ui_hints, isEdited, onEditStart, onEditFinish, working }) => {
    const isReadOnly = field_ui_hints && field_ui_hints["readonly"]
    const defaultNullPlaceholder = "undefined"
    field_ui_hints = field_ui_hints || {}
    const [tempValue, setTempValue] = useState("");
    useEffect(() => {
        setTempValue(isEdited ? fieldValue : "")
    }, [isEdited])
    const _onEditFinish = (e) => {
        onEditFinish && onEditFinish(tempValue)
    }

    function getEditElement(type) {
        if (type?.startsWith("Dict<string,")) {
            return (<>
                <AutoUI value={fieldValue} ui_hints={field_ui_hints?.subfields} />
                <Button size="small" label="Add" />
            </>)
        }
        else if (tempValue && typeof (tempValue) === "object") {
            return "Object"
        }
        // else if ((type == "bool" || typeof (fieldValue) === "bool")) {
        //     return (
        //         <CheckBox value={fieldValue} onChange={(e) => setTempValue(e.target.value)} />
        //     )
        // }
        else if (!field_ui_hints?.options && (type == "string" || !fieldValue || typeof (fieldValue) === "string")) {
            return (
                <TextInput style={{ width: `${((fieldValue || field_ui_hints?.new_val_placeholder || defaultNullPlaceholder).toString().length) * 0.5}em` }}
                    value={tempValue}
                    onChange={(e) => setTempValue(e.target.value)}
                    focusIndicator={false}
                    autoFocus
                    
                    className={"textInput " + (size || "small")}
                    onBlur={(e) => _onEditFinish(e)}
                />
            )
        }
        
        else if (field_ui_hints?.options) {
            return (

                <Select options={field_ui_hints?.options}
                    size={size || "small"}
                    className={"textInput " + (size || "small")}
                    value={tempValue}
                    open={true}
                    onBlur={(e) => _onEditFinish(e)}
                    onClose={(e) => _onEditFinish(e)}
                    onChange={(e) => {

                        setTempValue(e.target.value)
                        onEditFinish && onEditFinish(e.target.value)
                    }} />

            )

        }
        else {
            return (
                <>edit not supported</>
            )
        }

    }


    return (
        <Box className="fieldValue" focusIndicator={false} direction="row" align="center" onClick={() => !isEdited && onEditStart()}>
            
            {

                isEdited ? (
                    getEditElement(field_ui_hints?.type)
                ) :
                    (
                        (field_ui_hints?.type=="bool")?(
                            <Box margin="0px 5px">

                            <CheckBox  checked={fieldValue||false} 
                            disabled={isReadOnly}
                            onChange={(e) => {
                                setTempValue(e.target.checked)
                                onEditFinish(e.target.checked)
                            }} />
                            
                            </Box>
                        ):(
                            fieldValue ? (
                                <Box pad="1px 5px">
                                <Text size={size || "small"} truncate>
                                    {fieldValue?.toString()}
                                </Text> 
                                </Box>
                            ) : (
                                <Box pad="1px 5px">
                                    <Text weight={400} size={size || "small"} truncate color="gray">{field_ui_hints?.new_val_placeholder || defaultNullPlaceholder}</Text>

                                </Box>
                            )
                        )
                    )
            }
            {working && (
                <Box margin="0px 10px">
                    <Spinner className="miniSpinner" pad="1px" size="small" />
                </Box>
            )}
            {!isReadOnly && !isEdited ? (
                <Box margin="5px" className="editIcon" align="center" justify="center">
                    <Edit color="gray" size="15px" />
                </Box>
            ) : (
                <></>
            )
            }

        </Box>
    )
}



const RemoveKeyButton = ({ onClick }) =>
(
    <Box focusIndicator={false} onClick={onClick}>
        <Close size="15px" color="red" />
    </Box>
)

export const AutoUI = ({ value, ui_hints, size, type = "object", allowRemoveKey, onValueChange }) => {

    const [valueState, setValueState] = useState({});

    useEffect(() => {
        setValueState(value || {})
    }, [value])

    const fields = useMemo(() => {
        let newFields = [];
        if (ui_hints) { //UI hinst for dict should be key,/value... and these are not to be propagated to fields
            if (!type.startsWith("Dict<")) {
                Object.getOwnPropertyNames(ui_hints).forEach(f => newFields.push(f))
            }

        }
        if (value) {
            Object.getOwnPropertyNames(value).forEach(f => (!newFields.includes(f)) && newFields.push(f))
        }
        return newFields;

    }, [value, ui_hints])

    const [editedField, setEditedField] = useState()

    function modifyValue(modifyFucn) {
        let newValueState = Object.assign({}, valueState);
        modifyFucn(newValueState)
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }
    function setValue(path, val) {
        let newValueState = Object.assign({}, valueState);
        let current = newValueState;
        let pathArr = path.split(".")
        pathArr.forEach((prop, i) => {
            if (i != pathArr.length - 1) {
                let newCurrent = current[prop]
                if (!newCurrent) {
                    newCurrent = {}
                    current[prop] = newCurrent
                } else {
                    newCurrent = Object.assign({}, newCurrent)
                    current[prop] = newCurrent
                }
                current = newCurrent
            }
        })
        current[pathArr[pathArr.length - 1]] = val
        setValueState(newValueState);
        onValueChange && onValueChange(newValueState)
    }

    function getFieldHints(field) {
        if (type.startsWith("Dict<")) {
            let res = (ui_hints && ui_hints["value"])
            if (!res["type"]) {
                //push type from dict if not set in the value settings
                res["type"] = type.split(";")[1].slice(0, -1)
            }
            return res
        }
        else {
            return (ui_hints && ui_hints[field]) || {}
        }
    }
    function isFieldDict(field) {
        return getFieldHints(field).type?.startsWith("Dict<string")
    }


    function evaluateShowCondition(show_condition) {
        if (show_condition) {

            let not_met_found =  Object.getOwnPropertyNames(show_condition).find(f =>!(valueState[f] == show_condition[f] || (!!valueState[f] ===  show_condition[f])))
            if (not_met_found) {
                return false
            }
        }

        return true;
    }

    return (
        <>
            <table width="10%" className={"autoUIGrid " + size}>
                <tbody>
                    {fields && fields
                        .filter(field =>
                            evaluateShowCondition(getFieldHints(field).show_condition)
                        )
                        .map((field, i) => (



                            ((valueState[field] != null && typeof (valueState[field]) == "object") && !Array.isArray(valueState[field]) || isFieldDict(field) || (getFieldHints(field).subfields)) ?
                                (<tr key={i}>
                                    <td colSpan={2}>
                                        <Box direction="row">
                                            <Text className="nowrap" size={size || "small"} weight={800} >{getFieldHints(field).label || field}</Text>
                                            {allowRemoveKey && (
                                                <RemoveKeyButton onClick={() => {
                                                    modifyValue((val) => {
                                                        delete val[field]
                                                    })
                                                }
                                                }
                                                />
                                            )}
                                        </Box>
                                        <Box pad="0px 0px 10px 20px" >
                                            <AutoUI
                                                value={valueState[field]}
                                                ui_hints={getFieldHints(field).subfields}
                                                size={size}
                                                type={getFieldHints(field).type}
                                                allowRemoveKey={isFieldDict(field)}
                                                onValueChange={newVal => setValue(field, newVal)}
                                            />
                                            {isFieldDict(field) && (
                                                <Box>
                                                    <FieldValue
                                                        size={size}
                                                        field_ui_hints={{ new_val_placeholder: "Add new key" }}
                                                        onEditStart={() => setEditedField(field)}
                                                        onEditFinish={(val) => {
                                                            val && setValue(field + "." + val, "")
                                                            setEditedField(undefined)
                                                        }}
                                                        isEdited={editedField == field}
                                                    />

                                                </Box>
                                            )}
                                        </Box>

                                    </td>
                                </tr>
                                ) :
                                (
                                    <tr key={i}>
                                        <td>
                                            <Box margin="0px 10px 0px 0px" >

                                                <TipIfContent content={getFieldHints(field).tip || ""}>
                                                    <Box direction="row">

                                                        <Text weight={600} size={size || "small"} truncate>
                                                            {(ui_hints && ui_hints[field] && ui_hints[field]["label"]) || field}
                                                        </Text>
                                                        {getFieldHints(field).tip && <Text size="0.6em">?</Text>}
                                                    </Box>
                                                </TipIfContent>

                                            </Box>
                                        </td>
                                        <td>


                                            {(getFieldHints(field)?.type == "string[]") ? (
                                                <Box>
                                                    {value[field]?.map((v, i) => (
                                                        <FieldValue
                                                            size={size}
                                                            fieldValue={valueState[field][i]}
                                                            field_ui_hints={getFieldHints(field)}
                                                            onEditStart={() => setEditedField(field+"."+i)}
                                                            onEditFinish={(val) => {
                                                                if (val) {
                                                                    valueState[field][i] = val
                                                                    setValue(field, [...valueState[field]])
                                                                }
                                                                else {
                                                                    valueState[field].splice(i, 1)
                                                                    setValue(field, [...valueState[field]] )
                                                                }
                                                                setEditedField(undefined)
                                                            }}
                                                            isEdited={editedField == field+"."+i}
                                                        />
                                                    ))}
                                                    <FieldValue
                                                        size={size}
                                                        field_ui_hints={{ new_val_placeholder: "Add new" }}
                                                        onEditStart={() => setEditedField(field+".new")}
                                                        onEditFinish={(val) => {
                                                            val && setValue(field, [...(valueState[field] || []), val])
                                                            setEditedField(undefined)
                                                        }}
                                                        isEdited={editedField == field+".new"}
                                                    />
                                                </Box>

                                            ) : (

                                                <Box direction="row" align="center">
                                                    <FieldValue
                                                        size={size}
                                                        fieldValue={valueState[field]}
                                                        field_ui_hints={getFieldHints(field)}
                                                        onEditStart={() => setEditedField(field)}
                                                        onEditFinish={(val) => {
                                                            setEditedField(undefined)
                                                            if (val != valueState[field]) {
                                                                modifyValue(state => state[field] = val)
                                                            }
                                                        }}
                                                        isEdited={editedField == field}
                                                    />
                                                    {allowRemoveKey && (
                                                        <Box focusIndicator={false} onClick={() => {
                                                            modifyValue((val) => {
                                                                delete val[field]
                                                            })
                                                        }}><Close color="red" /></Box>
                                                    )}
                                                </Box>
                                            )}
                                        </td>
                                    </tr>
                                )

                        ))}
                </tbody>
            </table>
        </>
    )
}