import { BiCommentCheck, BiMessageCheck } from "react-icons/bi";
import { FaBullhorn, FaCheckDouble, FaCogs, FaFacebook, FaInfo, FaInstagram, FaLightbulb, FaQuestion, FaReply, FaStar, FaThumbsUp, FaTimes, FaYoutube } from "react-icons/fa"
import { GoCommentDiscussion, GoFlame, GoReport } from "react-icons/go";
import { GrFormClose, GrLike, GrMoreVertical } from "react-icons/gr";
import { ImArrowDown2, ImQuotesRight } from "react-icons/im";
import { IoMdHand } from "react-icons/io";
import { MdCancel, MdDeleteOutline } from "react-icons/md";

const catalogue={
    "youtube":<FaYoutube color="red"/>,
    "facebook":<FaFacebook color="#1877f2"/>,
    "instagram":<FaInstagram color="#ff5400"/>,
    "likes":<GrLike/>,
    "shock":  "😱",
    "reply": <FaReply/>,
    "laugh":"🤣",
    "disgust":"🤮",
    "anger":"😡",
    "love":"❤️",
    "bot":"🤖",
    "toxic":"☠️",
    "like":"👍",
    "dont_like":"👎",
    "childish":"🤡",
    "noteworthy":"⭐️",
    "question":<FaQuestion/>,
    "feedback":<FaBullhorn/>,
    "laughting":"😂",
    "joking":"🤡",
    "suggestion":"💡",
    "hello":"👋",
    "opition":"🧐",
    "support":"🙌",
    "hot-topics":"🔥",
    "information":<FaInfo color="rgb(100,120,250)"  style={{margin:"2px 0px -2px 0px"}}/>,
    "irrelevant":<ImArrowDown2/>,
    "answer":<FaReply color="rgb(50,50,250)"/>,
    "agree":<BiMessageCheck color="rgb(0,190,0)" size="20px" style={{margin:"2px 0px -5px 0px"}}/>,
    "citation":<ImQuotesRight/>,
    "report":<GoReport/>,
    "delete":<MdDeleteOutline/>,
    "more":<GrMoreVertical/>,
    "automate":<FaCogs/>,
    "select-all":<FaCheckDouble/>,
    "close":<GrFormClose />
}

const catalogueBW={
    "youtube":<FaYoutube />,
    "facebook":<FaFacebook />,
    "instagram":<FaInstagram />,
    "likes":<FaThumbsUp/>,
    
    "shock":  "😱",
    "reply": <FaReply/>,
    "laugh":"🤣",
    "disgust":"🤮",
    "anger":"😡",
    "love":"❤️",
    "bot":"🤖",
    "toxic":"☠️",
    "like":<FaThumbsUp/>,
    "dont_like":"👎",
    "childish":"🤡",
    "noteworthy":<FaStar/>,
    "question":<FaQuestion/>,
    "feedback":<FaBullhorn/>,
    "laughting":"😂",
    "joking":"🤡",
    "suggestion":<FaLightbulb/>,
    "hello":<IoMdHand/>,
    "opition":"🧐",
    "support":"🙌",
    "hot-topics":<GoFlame />,
    "information":<FaInfo  style={{margin:"2px 0px -2px 0px"}}/>,
    "irrelevant":<ImArrowDown2/>,
    "answer":<FaReply />,
    "agree":<BiMessageCheck  size="20px" style={{margin:"2px 0px -5px 0px"}}/>,
    "citation":<ImQuotesRight/>,
    "report":<GoReport/>,
    "delete":<MdDeleteOutline/>,
    "more":<GrMoreVertical/>,
    "automate":<FaCogs/>,
    "select-all":<FaCheckDouble/>,
    "close":<GrFormClose />
}

const getIcon=(iconId:"youtube"|"facebook"|"instagram"|"likes"|"reply"|"close"|"shock"|"laugh"|"disgust"|"anger"|"love"|"bot"|"toxic"|"like"|"dont_like"|"childish"|"noteworthy"|"question"|"feedback"|"laughting"|"joking"|"suggestion"|"hello"|"opition"|"support"|"hot-topics"|"information"|"irrelevant"|"agree"|"answer"|"citation"|"report"|"delete"|"more"|"automate"|"select-all"|"none", blackAndWhite:boolean|undefined=false)=>{
    if (iconId==="none"){
        return(<></>)
    }
    else if (catalogue.hasOwnProperty(iconId)){

        return (
             <div>{!blackAndWhite? catalogue[iconId] : catalogueBW[iconId]}</div>
        );
    }
    
    else
        return <MdCancel />
}

export {getIcon};

