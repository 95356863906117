import {Box, Tip} from "grommet"

const TipIfContent = ({content, children})=>{
    if (content){
        return (
            <Tip content={content} > 
                    {children}                
            </Tip>
        )
    }
    else{
        return ( children)
    }


}

export {TipIfContent};