import React, { useContext, createContext,useState, useEffect } from "react";
import {auth} from "./firebase";
import {  signInWithPopup, sendSignInLinkToEmail,GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";

const AuthContext = React.createContext();

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: process.env.REACT_APP_PUBLIC_URL +'/finishSignUp',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    // dynamicLinkDomain: 'example.page.link'
  };

export function useAuth(){
    return useContext(AuthContext);
}

export function AuthProvider({children}){
    const [currentUser, setCurrentUser] = useState();
    
    


    useEffect(()=>{
        return auth.onAuthStateChanged(user=>{
            setCurrentUser(user)
        });
    },[]);

    function logout(){
        return auth.signOut();
    }

    function signUp(email, onResult){
        
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        .then(() => {
          // The link was successfully sent. Inform the user.
          // Save the email locally so you don't need to ask the user for it again
          // if they open the link on the same device.
          window.localStorage.setItem('emailForSignIn', email);
          onResult({success:true});
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
            //   console.log(error.message)
          onResult({errror:error});
          // ...
        });
    }

 
    function signUpWith(provider){
        signInWithPopup(auth, provider)
        .then((result) => {
            setCurrentUser(result.user);
            // ...
        }).catch((error) => {
            console.log(error.message)
            //setError(error.message)
        });
    }

    const value = {
        currentUser,
        signUp,
        signUpWith,
        logout,
        setCurrentUser
    };

    return (
        <AuthContext.Provider value={value}>
        {children}
        </AuthContext.Provider>
        
        );
}