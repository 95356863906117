import { Layer,Spinner,Text,Box, Button, TextInput } from "grommet";
import { Copy, FormClose } from "grommet-icons";
import { useState } from "react";
import getApi from "../../Middleware/apiService";

const InviteToConnectDialog=({project_id, onClose})=>{
    
    const [inviteEmail, setInviteEmail]=useState("");
    const [inviteLink, setInviteLink]=useState("");
    const [error, setError]=useState(undefined);
    const validateEmail = (email) => {
    

        let res = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(String(email));
    
        return res;
      };
    function generateInviteLink(){
        
        setError()
        if (inviteEmail && validateEmail(inviteEmail)){
            getApi().generateClientInviteLink(inviteEmail,project_id).then(
                (link)=>{
                    setInviteLink(link)
                    setInviteEmail("")
                }

                ).catch(err=>setError(err.message))
        }
        else{
            setError("Invalid email")
        }

    }
    return (
        <Layer onClickOutside={()=>onClose()} >
            <Box pad="small"  gap='medium' flex="grow" >
            <Box direction="row" align="center" justify="between" flex="grow">

                <Text>Invite client to connect social account</Text>
                <Box alignSelf="end" pad="15px" focusIndicator={false} onClick={()=>onClose()}><FormClose/></Box>
            </Box>
                <Box direction="row" wrap gap="small" flex="grow" width="550px" >
                    <Box  width="300px" >

                    <TextInput placeholder="enter invitee email" value={inviteEmail} onChange={e=>setInviteEmail(e.target.value)} />
                    </Box>
                  
                    <Button margin="5px 0px" primary label="Generate invite link" onClick={()=>generateInviteLink()}/>
                  <Box justify="stretch" width="100%" direction="row">
                    <TextInput value={inviteLink} width="100%" size="10pt" style={{padding:"2px"}}/>
                    <Box width="20px" align="center" justify="center" onClick={()=>navigator.clipboard.writeText(inviteLink) }><Copy size="15px"/></Box>
                  </Box>
            
                </Box>
                <Box align="end">

                {error&&(<Text color="red">{error}</Text>)}

                <Button alignSelf="center" primary label="Close" onClick={()=>onClose()}/>
                </Box>
            </Box>
        </Layer>
    )
}

export {InviteToConnectDialog};