import { Box, DataChart, DropButton, Spinner, Text, Tip } from "grommet"
import { Refresh } from "grommet-icons"
import { useEffect, useMemo, useState } from "react"
import { BiCalendar } from "react-icons/bi"
import { FaPercent } from "react-icons/fa"
import {TiSortNumerically} from "react-icons/ti"
import { useAppContext } from "../../appContexst"
import getApi from "../../Middleware/apiService"
import { AccountsFilter } from "../AccountsFilter/AccountsFilter"
import { AuthorAnalytics } from "../AuthorAnalytics/authorAnalytics"
import { DateRange } from "../DatePicker/datePicker"
import { LinkedAccountTag } from "../LinkedAccountTag/LinkedAccountTag"
import { Widget } from "./widget"



interface MetricData{
    data:{day:string, count:number}[],
    total?:number
}

const OptionsSelector = ({icon, options, value, setValue}:{
    icon:any,
    options:string[]
    value:string,
    setValue:(val:string)=>any
}
    )=>{
   
    
   
    const [currentOption, setCurrentOptions] = useState<string|undefined>()
   
    const [dropOpen, setDropOpen] = useState(false)
    return (
        <Box direction="row" align="center" gap="small">
            
        <DropButton
        dropAlign={{top:"bottom"}}
        open={dropOpen}
        onOpen={()=>setDropOpen(true)}
        onClose={()=>setDropOpen(false)}
        label={ 
        <Box direction="row" align="center" justify="center" gap="small">
            {icon}
            <Text size="small">
            {value}
            </Text> 
        </Box>
        }
        dropContent={
        <Box pad="5px" round="2px" gap="2px">
            {(options).map(o=>(
                <Box onClick={()=>{
                    setValue(o)
                    setDropOpen(false)
                    
                }
                }>
                    {o}
                </Box>
            ))}
        </Box>}
        />
        </Box>
     
    )
}

export const Statistics = ({project_id,projectInfo,  onOpenWidgetDetail, metrics, allAccounts, selectedAccounts=null, onSelectedAccountsChange=undefined}:{
    project_id:string,
    projectInfo:any,
    onOpenWidgetDetail?:({metric,options,author, since, until}:{metric:string, options:any, author?:string, since?:Date, until?:Date})=>any,
    metrics?:string[],
    allAccounts:{original_key:string}[],
    selectedAccounts:string[],
    onSelectedAccountsChange:undefined|((newVal:string[])=>any)
}) => {
    const {setCacheValue, getCacheValue} = useAppContext()

    const [since, setSince] = useState<Date>()
    const [until, setUntil] = useState<Date>()
    const [units, setUnits] = useState<string>("Absolute")
    const [data, setData] = useState<any>()
    
    const [isLoading, setIsLoading] = useState(undefined);
   

    useEffect(()=>{
        if (selectedAccounts && since && until){
            reload()
        }
    },[selectedAccounts,since,until])
    // useEffect(()=>{
    //     if (allAccounts && !selectedAccountsInternal){
    //         setSelectedAccounts(allAccounts.map(a=>a.original_key))
    //     }
    // },[allAccounts])
   
    const METRICS_OPTIONS={
        "billing_units":{label:"Billing units",  color:"#e43993", tip:"Total billed units, consists of number of comments processed and total actions taken over the comments"},
        "total":{label:"Overall processed",  color:"#e43993",  tip:"Total number of incoming comments"},
        "hidden":{label:"Comments hidden",  color:"#e43993", relative_to:"total", tip:"Total number of comments that were hidden by this tool (excluding those unhidden)" },
        "replied":{label:"Comments replied",  color:"#e43993",relative_to:"total",  tip:"Total number of comments that were replied to by this tool"  },
        "deleted":{label:"Comments deleted",  color:"#e43993",relative_to:"total",  tip:"Total number of comments that were deleted to by this tool"   },
        "unhidden":{label:"Comments unhidden",  color:"#e43993" ,relative_to:"total",  tip:"Total number of comments that were originally hidden, but were unhidden by an operator" },
        "handling_auto":{label:"Handled automatically",relative_to:"total",  tip:"Total number of comments that were processed fully automatically (without review by an operator)"},
        "handling_review":{label:"Handled with review", relative_to:"total",  tip:"Total number of comments that were passed to review by an operator"},
        "human_review_confirmed":{label:"Human review confirmed", relative_to:"handling_review",  tip:"Total number of comments operator reviewed with the same result as the AI"},
        "human_review_corrected":{label:"Human review corrected",  relative_to:"handling_review",  tip:"Total number of comments that the operator corrected"},
        "handling_manual":{label:"Handled manually", relative_to:"total", tip:"Total number of comments handled by an operator"},
        "errors":{label:"Errors", color:"rgb(220,50,50)",  relative_to:"total", tip:"Errors in communication with the connected app"},
    }
    const temp={}

  
    function getCacheKey(metric:any,since:any, until:any, accounts:string[]){
        let params =new URLSearchParams({
            metric:metric,
            since: new Date(since.toISOString().substring(0, 10)).toISOString(),
            until: new Date(until.toISOString().substring(0, 10)).toISOString()
        })
        accounts?.forEach(a=>params.append("a",a))
        return params.toString()
    }

    function dateAsUtc(date:Date){
        
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    }
    function reload(force:boolean=false){
        setIsLoading(true)
        if( since && until){
        let cacheKey = getCacheKey("all",since,until,selectedAccounts )
        if (!force && getCacheValue(cacheKey)){
            setData(getCacheValue(cacheKey))
            return
        }
        let stats_promisse= Object.keys(METRICS_OPTIONS).map(metric=>{

                return getApi().getStats(
                    project_id, 
                    metric,
                    dateAsUtc(since),
                    dateAsUtc(until),
                    selectedAccounts!==undefined?selectedAccounts:null
                    ).then((metricData: any) => {
                        (temp as any)[metric]=metricData
                        let newData={...temp}
                        setData(newData)
                        
                        return newData
                    })
                    
                })
                
                Promise.all(stats_promisse).then(data=>{
                    setIsLoading(false)
                    setCacheValue(cacheKey,data[data.length-1])
                })
        }
    }

    const default_actionable_label=useMemo(()=>{
        if (projectInfo){
            return ( projectInfo?.labels_actions? (Object.keys(projectInfo.labels_actions).filter(t=>projectInfo.labels.includes(t))[0]):null) ||  projectInfo?.labels &&  projectInfo?.labels[0]
        }
    },[projectInfo])
 
    function relativeToData(metricData:MetricData, totalData:MetricData){
        if (metricData && totalData)
        {
            let newData = metricData.data.map((v,i)=>({
                day:v.day, 
                count:v.count?Math.round(((v.count/totalData.data.find(tv=>tv.day.substring(0,10)==v.day.substring(0,10)).count)*100)):0
            }))

            
            return ({
                data:newData,
                total:Math.round((metricData.total/totalData.total)*100)
            })
        }
    }
    return (
        <Box>
             {<AccountsFilter allAccounts={allAccounts} onSelectedAccountsChange={onSelectedAccountsChange} selectedAccounts={selectedAccounts}/>}      
            <Box direction="row" justify="between">
           
                <Box direction="row" align="center" gap="small">
                <Text size="10pt">Scale</Text>
                <OptionsSelector 
                    options={["Absolute","Relative"]}
                    icon={units=="Absolute"?<TiSortNumerically/>:<FaPercent/>}
                    setValue={setUnits}
                    value={units}
                    />
                </Box>
                <DateRange onRangeChanged={({since,until})=>{
                    setSince(since)
                    setUntil(until)
                } }/>
            </Box>
            {isLoading?(<Box align="center" justify="center" pad="50px"><Spinner size="large"/></Box>):(<Box>
            <Box direction="row-responsive" wrap >
                { Object.keys(METRICS_OPTIONS).filter(t=> metrics? metrics.includes(t):true).map(metric=>(
                <Widget 
                    project_id={project_id}
                    unit={(units=="Relative"&&  (METRICS_OPTIONS as any)[metric].relative_to)?"%":null}
                    metric={metric} 
                    data={data  && data[metric] && ((units=="Absolute" || !(METRICS_OPTIONS as any)[metric].relative_to) && data.total? data[metric]:relativeToData(data[metric],data[(METRICS_OPTIONS as any)[metric].relative_to] ) )}
                    label={(METRICS_OPTIONS as any)[metric].label}
                    color={(METRICS_OPTIONS as any)[metric].color}
                    since={since}
                    until={until}
                    tip={(METRICS_OPTIONS as any)[metric].tip}
                    onClick={()=>onOpenWidgetDetail&&onOpenWidgetDetail({metric, options:(METRICS_OPTIONS as any)[metric], since, until})}
                />
                ))}
            </Box>
            <AuthorAnalytics project_id={project_id} since={since} until={until} accounts={selectedAccounts} onAuthorPostClick={({author, label}:any)=>{
                onOpenWidgetDetail({metric:null, options:{label:label}, author:author.id, since,until})
                }}
                all_labels={projectInfo?.labels}

                default_label={default_actionable_label}
            />
            </Box>)}
    </Box>
    )
}