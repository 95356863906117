import { Layer,Spinner,Text,Box, Button, TextInput, Accordion, AccordionPanel, CheckBox, Tip } from "grommet";
import { Copy, FormClose, LinkTop } from "grommet-icons";
import { useState } from "react";
import getApi from "../../Middleware/apiService";
import { ExpandingButton } from "../ExpandingButton/expandingButton";
import { LinkedAccountBox } from "../LinkedAccountBox/LinkedAccountBox";

const ConfirmAccountsDialog=({accountsToConfirm, accountsInOtherProjects, errors, onConfirm, onCancel})=>{
    

    

    const [accordionOpened, setAccordionOpened]=useState(!accountsToConfirm?.length);
    
    const [_accountsToConfirm, setAccountsToConfirm]=useState(accountsToConfirm);
    const [_accountsInOtherProjects, setAccountsInOtherProjects]=useState(accountsInOtherProjects);

    return (
        <Layer onClickOutside={()=>onCancel()} >
            <Box pad="small"   flex="grow" justify="start"  >
                <Box direction="row" align="center" justify="between" width="100%">

                    <Text>Confirm accounts to link</Text>
                    <Box alignSelf="end" pad="15px" focusIndicator={false} onClick={()=>onCancel()}><FormClose/></Box>
                </Box>
                
                <Text size="small">Confirm accounts that should be linked to this project</Text>
                
                <Box  gap="xsmall" flex="grow" style={{minWidth:"550px"}} justify="stretch" >
                    {(accountsToConfirm?.length || _accountsToConfirm?.length) ? (
                      (  [...(new Set([...(_accountsToConfirm||[]),...(accountsToConfirm||[])]))]).map((a,i)=>(
                            <Box key={i} direction="row" gap="xsmall">
                                <CheckBox checked={_accountsToConfirm.includes(a) } onChange={(e)=>{
                                    if (e.target.checked){
                                        if (!_accountsToConfirm.includes(a)){
                                            setAccountsToConfirm([..._accountsToConfirm,  accountsToConfirm.find(t=>t==a)])
                                        }
                                    } 
                                    else{
                                        setAccountsToConfirm(_accountsToConfirm.filter(t=>t!=a))
                                        if (accountsInOtherProjects.map(t=>t.original_key).includes(a.original_key)&& !_accountsInOtherProjects.map(t=>t.original_key).includes(a.original_key)){
                                            setAccountsInOtherProjects([..._accountsInOtherProjects,  a])
                                        }
                                    }
                                }
                                }/>
                                <LinkedAccountBox account={a} />
                            </Box>
                        ))
                    ):(
                        <Box pad="50px" width="100%" flex="grow" align="center" >
                            <Text weight={600}>
                                No new accounts
                            </Text>
                        </Box>
                    )}
                </Box>
                {(_accountsInOtherProjects&&_accountsInOtherProjects?.length) ? (
                    <Accordion margin="10px 0px 10px" activeIndex={accordionOpened?0:undefined} onActive={()=>setAccordionOpened(!accordionOpened)}>
                        <AccordionPanel label={
                            <Box direction="row">

                                <Text size="medium" weight={600}>Accounts already linked to other projects</Text>
                                <Box alignSelf="end" background="brand" round margin="0px 0px 5px 5px" pad="0px 5px"><Text size="10pt">{_accountsInOtherProjects.length}</Text></Box> 
                            </Box>
                        }>
                             {_accountsInOtherProjects&&(
                                _accountsInOtherProjects.map((a,i)=>(
                                    <Box key={i} direction="row" justify="stretch" gap="small" width="100%" >
                                  
                                            <Box align="center" justify="center"
                                                onClick={()=>{
                                                    console.log("a",a)
                                                    console.log("find", _accountsInOtherProjects.find(t=>t==a))
                                                    console.log("final", [..._accountsToConfirm,  _accountsInOtherProjects.find(t=>t==a)])
                                                    setAccountsToConfirm([..._accountsToConfirm,  _accountsInOtherProjects.find(t=>t==a)])
                                                    setAccountsInOtherProjects( _accountsInOtherProjects.filter(t=>t!=a))
                                                }}
                                            >
                                                <Tip content="Move to this project">
                                                <LinkTop/>
                                                </Tip>
                                            </Box>
                                                <LinkedAccountBox key={i} account={a}/>
                                            
                                        </Box>
                                    // <ExpandingButton 
                                    //     border={false}
                                    //     collapsedContent={
                                    //         <LinkedAccountBox key={i} account={a}/>
                                    //     }
                                    //     expandedContent={
                                    //     <Box direction="row" justify="stretch" gap="small" width="100%" >
                                    //         <Box width="80%">
                                    //             <LinkedAccountBox key={i} account={a}/>
                                    //         </Box>
                                    //         <Button primary label="Move to this project"/>
                                    //     </Box>}
                                    // />
                                ))
                            )}
                        </AccordionPanel>
                    </Accordion>
                ):(
                    <Box></Box>
                )}
                <Box>
                    {(errors)?.map(error=>(<Text size="small" color="red">{error}</Text>))}
                </Box>
                <Box align="end">


                    <Box direction="row" gap="xsmall">
                        <Button alignSelf="center" primary label="Confirm" onClick={()=>onConfirm(_accountsToConfirm.map(a=>a.original_key))}/>
                        <Button alignSelf="center"  label="Cancel" onClick={()=>onCancel()}/>
                    </Box>
                </Box>
            </Box>
        </Layer>
    )
}

export {ConfirmAccountsDialog};