import { useState, useEffect } from 'react';
import axios from 'axios';
function initFbSdk(){
    // window.fbAsyncInit = () => {
    //     window.FB.init({
    //         appId            : process.env.REACT_APP_FB_APP_ID,
    //         autoLogAppEvents : true,
    //         xfbml            : true,
    //         version          : 'v13.0'
    //     });
    // };
    // (function (d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) { return; }
    //     js = d.createElement(s); js.id = id;
    //     js.src = "https://connect.facebook.net/en_US/sdk.js";
    //     fjs.parentNode.insertBefore(js, fjs);
    // }(document, 'script', 'facebook-jssdk'));
}

const insta_scopes=['instagram_manage_comments','instagram_basic','pages_manage_metadata','pages_show_list']
    //,'pages_read_engagement', 'pages_manage_engagement']
const fb_scopes=['pages_read_engagement','pages_show_list','pages_manage_engagement','pages_read_user_content', 'pages_manage_metadata']

const authorizeAccess = (project_id, requested_scopes, callback) => {
    window.FB.getLoginStatus(function(response) {
        console.log(response);
        if (response.status === 'connected') {
            let permissions = window.FB.api(`${response.authResponse.userID}/permissions`, "GET",(permissions)=>{
                let forceReLogin = false;
                let missing_scopes =[]
                if (permissions.error?.code!=190){
                    forceReLogin=true;
                }
                else{
                    let granted_scopes = permissions.data.filter(p=>p.status==="granted").map(p=>p.permission)
                     missing_scopes = requested_scopes.filter(s=>!granted_scopes.includes(s))
                }
                if (forceReLogin || missing_scopes.length>0){
                    window.FB.login(function(response) {
                        if (response.status==="connected" && response.authResponse) {
                            callback(response.authResponse);
                        } else {
                            console.error('User cancelled login or did not fully authorize.')
                        }
                    }, {
                        scope:requested_scopes.join(),
                        auth_type: 'reauthorize',
                        enable_profile_selector:true
                    })
                }
                else{
                    callback(response.authResponse);
                }
                console.log(permissions);
            })
        }
        else{
            window.FB.login(function(response) {
                if (response.authResponse) {
                    callback(response.authResponse);
                } else {
                        console.error('User cancelled login or did not fully authorize.')
                }
            }, {
                scope:requested_scopes.join(),
                auth_type: 'reauthorize',
                enable_profile_selector:true
             })
        }
    });
}

const authorizeAccessFacebook = (project_id,callback) => {
    authorizeAccess(project_id,fb_scopes,callback);
}

const authorizeAccessInstagram = (project_id,callback) => {
    authorizeAccess(project_id,insta_scopes,callback);
}

const revokeAccess = (account, scopes, callback, reject) => {
    
       return window.FB.api("/me",function(response) {
            if (response.id && account.responsible_account.id!=response.id){
                reject(Error(`Sorry, but you are not logged in as ${account.responsible_account.name}, so you are not authorized to revoke this account access. Please login into facebook as owner of this connection in order to revoke its access`))
            }
            else {
                window.FB.getLoginStatus((response)=>{
                    if (response.status !== 'connected') {
                        window.FB.login(function(response) {
                            if (response.status === 'connected') 
                                return revokeAccess(account,scopes,callback);
                            else{
                                reject(Error(`Login was interrupted`))
                            }
                        });
                    }
                    else{
                        if (scopes){
                            for(let i =0; i<scopes.length; i++){
                                if (i!==scopes.length-1){
                                    window.FB.api(`${response.authResponse.userID}/permissions/${scopes[i]}`, "DELETE",null)    
                                }
                                else{
                                    window.FB.api(`${response.authResponse.userID}/permissions/${scopes[i]}`, "DELETE",null, callback);
                                }
                            }
                            callback(null)
                        }
                        else{
                            window.FB.api(`${response.authResponse.userID}/permissions`, "DELETE",null, ()=>{
                                window.FB.logout((response) => callback(response));
                            })
                        }
                    }
                })
            }
        });
}

 
function revokeInstagram(account,callback){
    return  new Promise((fullfill, reject)=> revokeAccess(account,insta_scopes, fullfill, reject)).then(callback)
}
function revokeFacebook(account,callback){
    return  new Promise((fullfill, reject)=> revokeAccess(account,fb_scopes, fullfill,reject)).then(callback)
}

export { authorizeAccessInstagram, authorizeAccessFacebook,revokeFacebook,revokeInstagram, initFbSdk};