// import { } from "react";
import {Box} from "grommet";
import { SettingsOption } from "grommet-icons";
import { Routes, Route } from "react-router-dom";
import { ClientPage } from "./Pages/ClientPage";
//import { PostDetail } from "./components/PostDetail";

import { LoginPage } from "./Pages/LoginPage";
import { SettingsPage } from "./Pages/SettingsPage";

import {TestPage} from "./Pages/TestPage";
// import Footer from "../components/Footer";
// import Header from "../components/Header";
// import routes from "./config";
// import { Styles } from "../styles/styles";
// import Home from "../pages/Home";
// import FinishSignUp from "../pages/FinishSignUp";

const PageRouter = () => {
  return (
    
    <>

        <Box style={{
          overflow:"auto"
        }}>
      <Routes>

          <Route path="/login" element={<LoginPage/>}/>
          <Route path="/settings" element={<SettingsPage mode="admin"/>}/>
          {/* <Route path="/client" element={<ClientPage/>}/> */}
          <Route path="/client" element={<SettingsPage mode="client"/>}/>
          <Route path="/" element={<LoginPage/>}/> 
        
          {/* <Route path="/demo" element={<PostDetail/>}/> */}

      </Routes>
         </Box>
    
    </>
  );
};

export default PageRouter;
