import { useState, useEffect } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar } from 'grommet';
import { FormClose, Play, Undo, Unlink } from "grommet-icons"
import {

    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    GoogleAuthProvider, FacebookAuthProvider
} from "firebase/auth";

import getApi from "../../Middleware/apiService"

import { useAuth } from "../../auth/AuthProvider"
import { auth } from "../../auth/firebase"
import { useNavigate } from 'react-router-dom';
import { RoundSmallButton } from '../roundSmallButton';
import { authorizeAccessInstagram, authorizeAccessFacebook, revokeAll, revokeFacebook, revokeInstagram, initFbSdk } from "../../Middleware/fabebookService"
import { PlatformIcon } from '../PlatformIcon';
import { getIcon } from '../IconCatalogue';
import { FaArchive, FaCheck } from 'react-icons/fa';
import { MdDeleteForever, MdOutlineDeleteForever } from 'react-icons/md';
import "./LinkedAccountTag.css"
import { Autocomplete } from '../Autocomplete';
import { ImWarning } from 'react-icons/im';
import { FcApproval } from 'react-icons/fc';
import { TipIfContent } from '../TipIfContent';


export const LinkedAccountTag = ({ account, isActive, onClick }) => {

  
    return (
        <Box align='start' justify='start' margin="2px 0px" >
        <Box  round="30px" pad="2px" flex="shrink" margin="0px 5px" background={isActive?"brand":undefined} border onClick={onClick}>
            <Box direction='row' justify='center'>
                <Box className='pageImgSmall'>

                    <img src={account.avatar_url} />
                </Box>
                <Box justify='center'>
                    <Box align='start' margin="0px 0px 0px -8px" direction='row' gap="small">
                        <PlatformIcon platformName={account.platform} />
                        <Box >
                            <Box margin="0px 10px 0px 0px">
                            <Text size='11pt' className='accountName'>
                            {account.name} 
                            </Text>

                            </Box> 
                        </Box>
                        
                    </Box>
                </Box>
                <Box justify='between' align='center'>

                    
                
                </Box>
            </Box>
          
        </Box>
        </Box>
    )
}