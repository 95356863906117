import { ErrorFactory } from '@firebase/util';
import axios from 'axios';
import exp from 'constants';



import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Comment } from './DataInterfaces/Comment';
import { Options } from './DataInterfaces/Options';
import { Post } from './DataInterfaces/Post';
import { PostDetailsData } from './DataInterfaces/PostDetailsData';


let api:ApiService|null = null;
function getApi(user:any=null): ApiService{
  

    if (!api){ 
        const auth = getAuth();
        onAuthStateChanged(auth, (currentUser) => {
            if (api){
                api.currentUser=currentUser;
            }
        });
        
        //api = new ApiService(process.env.API_URL || "http://localhost:4000/api", auth.currentUser);
        api = new ApiService(  process.env.REACT_APP_API_PUBLIC_URL as string, auth.currentUser || user);
        //api = new ApiService("http://localhost:4000/", auth.currentUser);
    }
    
    return api;
}



class ApiService{
    baseUrl: string;
    currentUser: any;


    
    constructor(baseUrl: string, user:any) {
      this.baseUrl = baseUrl ;
      this.currentUser=user;
      
      axios.interceptors.request.use(
        async config =>  {
            if (!(api&&api.currentUser))
                await this.tryWaitForAuth(10)
            if ( api &&(  api.currentUser)){
                const token = await api.currentUser.getIdToken();
                if (token && config &&  config.headers) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
            }
            // config.headers['Content-Type'] = 'application/json';
            return config;
        },
        error => {
            
            Promise.reject(error)
        });
        axios.interceptors.response.use(undefined, (error)=>{
            if (error.response && error.response.status===403){
                (!window.location.href.endsWith("/login")) && setTimeout(()=>{window.location.href="/login?force=true"},500);
                return Promise.resolve(error.response);
            } 
            else
                return Promise.reject(error);
        })
    }
    
    async tryWaitForAuth(countdown:number) {
        if (!this.currentUser && countdown>0){
            await new Promise(r => setTimeout(r, 200)); //Wait 1s for auth
            if (!this.currentUser){
                await this.tryWaitForAuth(countdown-1)
            }
        }
    }

    generateClientInviteLink(invite_email:string, project_id:string){

        return axios.post((process.env.REACT_APP_LABELATORIO_API )+"/login/generate-invite-link",{
            invite_email:invite_email,
            new_tennant:"false",
            user_profile:"CLIENT",
            metadata:{
                client_id:invite_email,
                client_project_id:project_id
            },
            root_url: window.location.href.split('/settings')[0]+"/login"
        })
        .then((response) => {
           return response.data
        });
    }

    activateInvite(invite_id:string,invite_check:string, email:string, organization:string){
        
        return axios.put((process.env.REACT_APP_LABELATORIO_API) +"/login/activate-invite",null,{params:{
            invite_id:invite_id,
            invite_check:invite_check,
            email: email,
            organization
        }})
        .then((response) => {
           return response.data
        });
    }

    getSettings(project_id:string){
        return axios.get(this.baseUrl+"/settings", {params:{project_id}}).then(response=>response.data)
    }

    setLabel(comment_id:string, label:string){
        return axios.post(this.baseUrl+"/comments/"+comment_id, {label:label}).then(response=>response.data)
    }

    getClientAccounts(client_id:string){
        return axios.get(this.baseUrl+"/accounts").then(response=>response.data)
    }

    getActiveProjects():Promise<{id:string, name:string}>{
        return axios.get(this.baseUrl+"/active-projects").then(response=>response.data)
    }

    patchSettings(project_id:string,payload:any){
        return axios.patch(this.baseUrl+"/settings",payload , {params:{project_id}}).then(response=>response.data)
    }

    getStats(project_id:string, metric:string, since:Date, until:Date, accounts:null|string[]=null){
        var params = new URLSearchParams();
        
        accounts?.forEach(social_account=>params.append("social_account", social_account));
        if (project_id){
            params.append("project_id", project_id);
        }
        params.append("since", since.toISOString());
        params.append("until", until.toISOString());
        return axios.get(this.baseUrl+"/statistics/"+metric, {params:params}).then(response=>response.data)
    }

    getAuthorAnalytics({project_id=null, by_label, since, until, accounts=null}:
        {project_id?:string, by_label:string, since:Date, until:Date, accounts?:string[]}
        ){
        var params = new URLSearchParams();
        
        accounts?.forEach(social_account=>params.append("accounts", social_account));
        params.append("by_label", by_label);
        if (project_id){
            params.append("project_id", project_id);
        }

        params.append("since", since.toISOString());
        params.append("until", until.toISOString());

        return axios.get(this.baseUrl+"/analytics/authors", {params:params}).then(response=>response.data)
    }

    getSettingsInfo(project_id:string){
        var params = new URLSearchParams();
        
        
        if (project_id)
            params.append("project_id", project_id);
        
        return axios.get(this.baseUrl+"/info", {params:params}).then(response=>response.data)
    }

    getPosts({project_id,  accounts, since, until,  skip, take}:
        { 
            project_id: string, 
            
            accounts: string[], 
            since: Date, 
            until: Date, 
            skip: number, 
            take: number 
        }
        ){
        let queryParams = new URLSearchParams()
        
        accounts?.forEach(a=>queryParams.append("accounts",a))
        if (project_id){
            queryParams.append("project_id",project_id)
        }
       
        if (skip){
            queryParams.append("skip",skip.toString())
        }

        if (since){
            queryParams.append("since",since.toISOString())
        }
        if (until){
            queryParams.append("since",until.toISOString())
        }

        if (take){
            queryParams.append("take",take.toString())
        }

        return axios.get(this.baseUrl+"/posts?"+queryParams.toString()).then().then(response=>response.data)
    }

    get_post_stats(plaform:string, post_original_key:string){
        return axios.get(this.baseUrl+`/posts/${plaform}/${post_original_key}`).then(response=>response.data)
    }

    getComments({project_id, author, filter,owner_post_key, accounts, since, until,  skip, take}:
        { 
            project_id: string, 
            owner_post_key:string,
            author:string,
            filter: string, 
            accounts: string[], 
            since: Date, 
            until: Date, 
            skip: number, 
            take: number 
        }
        ){
        let queryParams = new URLSearchParams()
        
        accounts?.forEach(a=>queryParams.append("accounts",a))
        if (project_id){
            queryParams.append("project_id",project_id)
        }

        if (owner_post_key){
            queryParams.append("owner_post_key",owner_post_key)
        }

        if (author){
            queryParams.append("author",author)
        }

        if (filter){
            queryParams.append("filter",filter)
        }

        if (skip){
            queryParams.append("skip",skip.toString())
        }

        if (since){
            queryParams.append("since",since.toISOString())
        }
        if (until){
            queryParams.append("until",until.toISOString())
        }

        if (take){
            queryParams.append("take",take.toString())
        }

        return axios.get(this.baseUrl+"/comments?"+queryParams.toString()).then().then(response=>response.data)
    }
    // getToReply(accounts:string[]){
    //     return axios.get(this.baseUrl+"/latest?"+accounts?.map(a=>`accounts=${a}`).join("&")).then().then(response=>response.data)
    //     //return axios.get(this.baseUrl+"/to-reply?"+accounts?.map(a=>`accounts=${a}`).join("&")).then().then(response=>response.data)
    // }
    
    // activate(activationCode:string){
    //     return axios.post(this.baseUrl+"/auth/activate", {"activationCode":activationCode});
    // }

    // youtubeLinkChannel(channelId:string){
    //     return axios.put(this.baseUrl+`/youtube_auth/link_channel/${channelId}`);
    // }

    // youtubeAuthUrlGet(callbackUrl:string){
    //     return axios.get(this.baseUrl+"/youtube_auth/get_auth_url",{params:{final_redirect:callbackUrl}});
    // }
    // youtubeAuthRevoke(accountId:string, deleteData:boolean){
    //     return axios.delete(this.baseUrl+"/youtube_auth/revoke", {params:{account_id:accountId, delete_data:deleteData}});
    // }

    facebookAccountLink(access_token:string,project_id:string, move_if_exists:boolean){
        return axios.put(this.baseUrl+"/auth/accounts/facebook",{access_token:access_token},{params: {project_id:project_id, move_if_exists:move_if_exists}}).then(response=>response.data);
    }
    linkAccount(platform:string, access_token:string,project_id:string,  return_to_confirm:boolean,accounts_to_link:string[] ){
        return axios.put(this.baseUrl+"/auth/accounts/"+platform,{
                access_token:access_token,
                project_id:project_id,
                accounts_to_link:accounts_to_link,
            },{params: { return_to_confirm:return_to_confirm, move_if_exists:true}}).then(res=>res.data);
    }

    accountDelete(platform:string, account_id:string, deleteData:boolean){
        return axios.delete(this.baseUrl+"/auth/accounts/"+platform+"/"+account_id, {params:{delete_data:deleteData}});
    }




    // getComment(
    //     comment_id:string
    // ):Promise<Post>{
    //     return axios.get(this.baseUrl+`/comments/${comment_id}`).then(response=>{
    //         return response.data
    //     })
    // }

    // queryComments(
    //     queryParams?:queryCommentsParams
    // ):Promise<Comment[]>{
    //     return axios.get(this.baseUrl+"/comments/query", {params:queryParams}).then(response=>response.data)
    // }

    // getSubComments(
    //     parent_id:string,
    //     skip:number=0,
    //     take:number=100
    // ):Promise<Comment[]>{
    //     return axios.get(this.baseUrl+`/comments/${parent_id}/subitems`, {params:{skip:skip,take:take}}).then(response=>response.data)
    // }

    // getReplies(
    //     parent_id:string,
    //     skip:number=0,
    //     take:number=100
    // ):Promise<Comment[]>{
    //     return axios.get(this.baseUrl+`/comments/${parent_id}/replies`, {params:{skip:skip,take:take}}).then(response=>response.data)
    // }

    // getReplyStats(
    //     parent_id:string
    // ):Promise<{[category:string]:number}>{
    //     return axios.get(this.baseUrl+`/comments/${parent_id}/reply-stats`).then(response=>response.data)
    // }
    
    
    // removeFromParent(
    //     id:string
    //  ):Promise<Comment[]>{
    //      return axios.delete(this.baseUrl+`/comments/${id}/remove-from-parent`).then(response=>response.data)
    //  }

    // markCommentBookmarked(
    //    ids:string[],
    //    state:boolean
    // ):Promise<Comment[]>{
    //     return axios.post(this.baseUrl+`/comments/mark_bookmarked`, ids, {params:{state:state}}).then(response=>response.data)
    // }

    // queryPosts(
    //     queryParams:queryPostsParams
    // ):Promise<Post[]>{
    //     return axios.get(this.baseUrl+"/posts/query", {params:queryParams}).then(response=>response.data)
    // }


    // getPostStats(
    //     post_id:string
    // ):Promise<{category:string, count:number}[]>{
    //     return axios.get(this.baseUrl+`/posts/${post_id}/stats`).then(response=>response.data)
    // }
    
    // getPostDetails(
    //     post_id:string
    // ):Promise<PostDetailsData>{
    //     return axios.get(this.baseUrl+`/posts/${post_id}/details`).then(response=>response.data)
    // }

    // getPost(
    //     post_id:string
    // ):Promise<Post>{
    //     return axios.get(this.baseUrl+`/posts/${post_id}`).then(response=>{
    //         return response.data
    //     })
    // }


    // getPostTopics(post_id:string, skip:number=0, take:number=100, exlude_important:boolean=true){
    //     return axios.get(this.baseUrl+`/posts/${post_id}/topics`,  {params:{exlude_important:exlude_important, skip:skip,take:take}}).then(response=>response.data)
    // }


    // getOptions():Promise<Options >{
    //     return axios.get(this.baseUrl+"/options").then(response=>response.data)
    // }

    // getAutocompleteOptions(filter_id:string,searchTerm:string|undefined|null, id:string|undefined=undefined):Promise< {id:string,name:string}[] >{
    //     let queryParams = searchTerm?{"search":searchTerm}: (id?{"id":id} :undefined)
    //     return axios.get(this.baseUrl+`/options/${filter_id}/autocomplete`,{params: queryParams}).then(response=>response.data)
    // }
    
}

export interface queryPostsParams{
    platforms?:string[],
    accounts?:string[],
    from_date?:Date,
    to_date?:Date,
    take?:number,
    skip?:number
}

export interface queryCommentsParams{
    owner_post_id?:string|undefined|null,
    categories?:string|undefined|null|string,
    account?:string|undefined|null,
    group_by?:string|undefined|null,
    collapsed?:boolean|undefined|null,
    is_reviewed?:boolean|undefined|null,
    is_bookmarked?:boolean|undefined|null,
    from_date?:Date|undefined|null,
    to_date?:Date|undefined|null,
    take?:number|undefined|null,
    skip?:number|undefined|null
}

export default getApi;