import { useContext, createContext,useState, useEffect } from "react";


const AppContext = createContext(undefined);


export function useAppContext(){
    return useContext(AppContext);
}

export function AppContextProvider({children}){

    const [cache,setCache]=useState({})
    function getCacheValue(key){
        return cache[key];
    }

    function setCacheValue(key,value){
        let newCache={...cache};
        newCache[key]=value
        setCache(newCache)
    }
   
    const value = {
        cache,
        setCacheValue,
        getCacheValue
    };

    return (
        <AppContext.Provider value={value}>
        {children}
        </AppContext.Provider>
        
        );
}