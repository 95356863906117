import { useState, useEffect } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar } from 'grommet';
import { FormClose, Play, Undo, Unlink } from "grommet-icons"


import { RoundSmallButton } from '../roundSmallButton';

import { PlatformIcon } from '../PlatformIcon';

import "./LinkedAccountBox.css"

import { ImWarning } from 'react-icons/im';
import { FcApproval } from 'react-icons/fc';
import { TipIfContent } from '../TipIfContent';


export const LinkedAccountBox = ({ account, onRevoke }) => {
    const [isUnLinking, setIsUnLinking] = useState(false);
    const [unlinkPending, setUnlinkPending] = useState(account.status === "DELETING");

    const revoke = (account, deleteData) => {
        setIsUnLinking(false);
        setUnlinkPending(true);
        let res = onRevoke(account, deleteData)
        res.finally(() => {
            setUnlinkPending(false)
        })
    }
    return (
        
        <Box border round pad="5px" flex={false} margin="5px 0px" align='stretch'>
            <Box direction='row' justify='between' flex="grow">
                <Box direction='row' justify='between'>
                <Box className='pageImg' flex={false}>

                    <img src={account.avatar_url} width="50px" height="50px"/>
                </Box>
                <Box justify='center' flex="grow">
                    <Box align='start' margin="0px 0px 0px -15px" direction='row' gap="small">
                        <PlatformIcon platformName={account.platform} />
                        <Box >
                            <Box>
                            <Text>
                            {account.name} 
                            </Text>

                            </Box>

                            <Box >
                                <Text size="8pt">
                                    linked by {account.responsible_account?.name} 
                                </Text>
                                {unlinkPending && <Text margin="-2px 0px" size="10px">Please check for blocked popups</Text>}
                            </Box>
                            
                        </Box>
                        
                    </Box>
                </Box>
                </Box>
                    <Box justify="between"  margin="-3px 3px 2px 3px" align='center' >

                            {onRevoke ? 
                                <RoundSmallButton  icon={unlinkPending ? (<Spinner size="small" margin="-10px 0px" pad="10px" />) : !isUnLinking ? <FormClose size="15px" /> : <></>} onClick={() => setIsUnLinking(!isUnLinking)} />
                            :
                                <Box></Box>
                        }
                            <Box align='end'>
                            {account.status=="OK" ?(
                                <TipIfContent content={account.status_msg || "Synchronization OK"}>
                                    <Box><FcApproval/></Box>
                                </TipIfContent>
                            ):(
                                account.status?.toUpperCase()=="ERROR" ?(

                                        <TipIfContent content={account.status_msg}>
                                        <Box>  
                                            <ImWarning color="red"/>
                                        </Box>
                                        </TipIfContent>
                                ):(<></>)
                            )}
                            </Box>
                    
                    </Box>
                    
                
                        {
                            (isUnLinking == true) && (
                                <Box  justify='between' gap="small" align='center'>

                                    <Text size="small">Unlink account ? </Text>
                                    <Button size="small" secondary icon={<Unlink />} label="Unlink account" onClick={() => revoke(account, false)} />
                                    {/* <Button size="small" secondary icon={<FaArchive />} label="Preserve data" onClick={() => revoke(account, false)} /> */}
                                    {/* <Button size="small" primary icon={<MdOutlineDeleteForever />} label="Delete all data" onClick={() => revoke(account, true)} /> */}
                                    <Button plain icon={<Undo size="20px" />} onClick={() => setIsUnLinking(!isUnLinking)} label="Cancel"/>

                                </Box>
                            )
                        }
            </Box>
        </Box>
        
    )
}