import { Box, DataChart, Spinner, Text, Tip } from "grommet"
import { useEffect, useMemo, useState } from "react"
import getApi from "../../Middleware/apiService"

export const Widget = ({project_id, data, unit, metric, label, color=undefined, tip,onClick=undefined }:{
    project_id:string,
    data:any,
    unit:string,
    metric:string,
    label:string,
    color?:string,
    tip?:string|undefined|null
    since:Date|undefined,
    until:Date|undefined,
    onClick?:()=>any
}) => {

    // const [data, setData] = useState<{ data: { day: string, count: number }[], total: number }>()
    // useEffect(() => {
    //     if(metric && since && until){
           
            
    //         getApi().getStats(
    //                 project_id, 
    //                 metric,
    //                 new Date(since.toString().substr(0, 28)),
    //                 new Date(until.toString().substr(0, 28))
    //             ).then((newData: any) => {
    //                 setData(newData)
    //             })
    //     }
    // }, [since,until])

    const labelElement = useMemo(()=>{
        if (tip){
            return(
                <Tip content={<Text>{tip}</Text>}>
                    <Box direction="row" align="start">

                        <Text weight={700}>{label??metric}</Text><Text weight={700 } size="9pt">?</Text>
                    </Box>
                </Tip>
            )
        }
        else{
            <Text weight={700}>{label??metric}</Text>
        }
},[])
    return (
        <Box border round="xsmall" pad="small" flex={false} margin="5px" onClick={onClick}>
        
                
                    <Box >
                        <Box direction="row" justify="between">
                            <Box>

                                {labelElement}
                            </Box>
                            {data &&(
                                <Box align="end">

                                    <Text size="13pt">Total</Text>
                                    <Text margin="-10px 0px" size="40pt">{data.total || 0} {unit}</Text>
                                </Box>
                            )}
                        </Box>
                        
                        <Box width="400px" height="100px" flex={false}  align="stretch" alignSelf="end" justify="stretch" >
                                
                            {data ?( <DataChart
                             size={{height:"100px"}}
                              axis={false} 
                              detail 
                              
                              chart={[{property:"value", color:color} as any]}
                              data={data.data.map((d:any) => ({ date: d.day, value: d.count }))} series={[
                                {
                                label: "Date",
                                
                                property: "date",
                                render: (value, point, dataIndex) =>new Date(value).toLocaleDateString(),
                              }, 
                              {
                                label: unit||"Count",
                                
                                property: "value",
                                render: (value, point, dataIndex) =>value,
                              }
                            ]} 
                              />
                            )
                            :
                            (
                                <Spinner />
                            )}
                        </Box>
                    </Box>
            
        </Box>
    )
}