import { BrowserRouter } from "react-router-dom";



import './App.css';
import { Grommet, Grid } from 'grommet'
import PageRouter from "./PageRouter";

import { AuthProvider } from "./auth/AuthProvider";

import { MdAssistant, MdFilterFrames, MdOutlineExplore, MdBookmark, MdBubbleChart, MdModeComment } from "react-icons/md"
import { IoMdChatboxes } from "react-icons/io"
import { GoAlert } from 'react-icons/go';
import { FaCogs } from "react-icons/fa";
import { AppContextProvider } from "./appContexst";
const theme = {

  carousel: {
    icons: { color: "#b336b3" },
    disabled: { icons: { color: "transparent" } }
  },
  tip:{content:{
    background: "white",
    elevation: "medium", 
  }},
  global: {
    font:{size:"12pt"},
    focus:{ border: {color:"dark-2"}},
    colors: {
      active: "light-3",
      brand:"#e43993",
      "accent-1":"#ED9B40",
      focus: '#50c050',
      'dark-1': '#2a262a',
      'dark-2': '#4c484c',
      'dark-3': '#635d63',
      'dark-4': '#6f6b6f',
      'light-1': '#f5f3f5',
      'light-2': '#fffaffcc',
      'light-3': '#e8e3e8cc',
    },
  },
  tab: {
    color: 'text-strong',
    active: {
      
      color: 'brand',
    },
    hover: {
      
      color: 'text-strong',
    },
    border: {
      side: 'bottom',
      color: 'transparent',
      size: '6px',
      active: {
        color: 'brand',
      },
      disabled: {
        color: undefined,
      },
      hover: {
        color: undefined,
      },
    },

  
  }
};


const App = () => {
  console.log(process.env)
  return (
  <div id="app" className="app">

    <AuthProvider>
      <AppContextProvider>
      <Grommet theme={theme} style={{height:"100%"}}>
    
          <BrowserRouter>
            
            
            <PageRouter />

          </BrowserRouter>
        
      </Grommet>
      </AppContextProvider>
    </AuthProvider>
  </div>
)};

export default App;
