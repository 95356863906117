import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar, Tab, Tabs, InfiniteScroll } from 'grommet';
import { Refresh } from 'grommet-icons';
import { useEffect, useState } from 'react';
import { useAppContext } from '../../appContexst';
import getApi from '../../Middleware/apiService';
import { PostBox } from '../../PostBox/PostBox';
import { AccountsFilter } from '../AccountsFilter/AccountsFilter';
import { CommentBox } from '../CommentBox/CommentBox';
import { LinkedAccountTag } from '../LinkedAccountTag/LinkedAccountTag';

export const Posts = ({project_id, mode, projectInfo, allAccounts, selectedAccounts=null, onSelectedAccountsChange=undefined }) => {
    

    const [selectedAccountsInternal, _setSelectedAccountsInternal] = useState();

    function setSelectedAccounts(val){
        _setSelectedAccountsInternal(val)
        onSelectedAccountsChange&&onSelectedAccountsChange(val)
    }

    useEffect(()=>{
        if (selectedAccounts){
            _setSelectedAccountsInternal(selectedAccounts)
            
        }
    },[selectedAccounts])

    useEffect(()=>{
        
        reload(selectedAccounts)
        
    },[selectedAccounts])

    
    function toggleAccount(account_key){
        if (selectedAccountsInternal?.includes(account_key)){
            _setSelectedAccountsInternal(selectedAccountsInternal.filter(a=>a!=account_key))
            setCurrentPage(1)
        }
        else{
            _setSelectedAccountsInternal([...selectedAccountsInternal ||[], account_key])
            setCurrentPage(1)
        }

    }
    const pageSize=100;
    const [comments,setPosts] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [hasNextPage, setHasNextPage] = useState(true);
    const {setCacheValue, getCacheValue} = useAppContext()

    function getCacheKey( accounts){
        let params =new URLSearchParams()
        accounts?.forEach(a=>params.append("a",a))
        return params.toString()
    }

    const loadData = (project_id, page, accounts)=>{
       
        accounts=accounts || selectedAccountsInternal
        if (accounts){
            let cacheKey = getCacheKey(accounts)
            if (getCacheValue(cacheKey)){
                let data = getCacheValue()
                setPosts(data)
                setHasNextPage(data?.length>=pageSize)
                return
            }

            setIsLoading(true);    
            
            getApi().getPosts({project_id,  accounts:accounts, skip:pageSize*(page-1), take:pageSize}).then(data=>{
                setCurrentPage(page)
                if(page>1&&comments){
                    if (data?.length){
                        data = comments.concat(data);
                    }
                    else{
                        setHasNextPage(false)
                        return;
                    }
                }
                
                if (!(data?.length>=pageSize)){
                    setHasNextPage(false)
                }
                setPosts(data||[])
                setCacheValue(data||[])
            })
            .finally(()=>setIsLoading(false));
        }
        
       
      }
    
      const loadNextPage = ()=>{       
        loadData(project_id, currentPage+1);
      }

      function reload(accounts){
        setSelectedAccounts(accounts||selectedAccountsInternal)
        loadData(project_id,1, accounts||selectedAccountsInternal)
    }

    return (
        <Box >
            <Box direction='row' margin="10px 0px" flex={false} wrap>
               {<AccountsFilter allAccounts={allAccounts} onSelectedAccountsChange={onSelectedAccountsChange} selectedAccounts={selectedAccounts}/>}      
            </Box>
            {/* <Box flex={false} height="70vh" overflow="auto"> */}
            <Box flex="grow"  height="78vh"  overflow="auto">
                {isLoading ? <Spinner />:(
                    comments?.length?(
                    <InfiniteScroll 
                    
                    items={comments} 
                    step={5}
                    //chromatic= {{ disable: true }}
                    
                    // renderMarker={(marker)=>{
                    //         if (hasNextPage || isLoading){
                    //             return (<Box margin="small" align="center"><Spinner/> loading...{!isLoading && marker}</Box>)
                    //         }
                    //         else return (<Box margin="5px 30px" pad="2px 2px" round background="rgb(240,240,240)" align="center">{marker}<Text size='10pt'> the end (no more data) </Text>{(marker)}</Box>)
                    //     }
                    //     } 
                    onMore={() => { 
                        
                        if (hasNextPage){
                            loadNextPage();
                        }
                    }
                    }    
                    >
                    {(post,i, ref)=>(
                        <Box ref={ref} key={i} flex={false}>
                            <PostBox mode={mode} projectInfo={projectInfo} post={post} account={allAccounts.find(a=>a.original_key==post.social_account)}/>
                        
                        </Box>
                    )}
                    </InfiniteScroll>
                    ):(
                        <Box>Sorry, no data</Box>
                    )
                    )}
                
              
                {/* {comments && comments.map && comments.map(c => (
                    <CommentBox key={c._id} comment={c} onExplainPredictionsClick={onExplainPredictionsClick} />
                ))} */}
            </Box>
        </Box>

    )

}