import { useState, useEffect, useRef } from 'react';
import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar, Tab, Tabs, Select, DropButton } from 'grommet';
import {BarChart, FormPrevious, History, Play,Refresh,Return,Unlink, UserSettings } from "grommet-icons"
import getApi from "../../Middleware/apiService"
import { DateRange, getRelativeDate,addSeconds } from '../DatePicker/datePicker';
import { FaUserCircle } from 'react-icons/fa';
import { useAppContext } from '../../appContexst';


const LabelPicker = ({labels, value, onValueChanged})=>{
    
   
   
    
  

    const [dropOpen, setDropOpen] = useState(false)
    return (
        
        <DropButton
        dropAlign={{top:"bottom"}}
        open={dropOpen}
        onOpen={()=>setDropOpen(true)}
        onClose={()=>setDropOpen(false)}
        label={ 
        <Box direction="row" align="center" justify="center" gap="small">
            
            <Text size="small">
            By label: {value}
            </Text> 
        </Box>
        }
        dropContent={
        <Box pad="5px" round="2px" gap="2px">
            {labels && labels.map(o=>(
                <Box onClick={()=>{
                    onValueChanged(o)
                    setDropOpen(false)
                }
                }>
                    {o}
                </Box>
            ))}
        </Box>}
        />
        
     
    )
}

export const AuthorAnalytics = ({project_id,since, until, accounts, all_labels, default_label, onAuthorPostClick})=>{

    const [data,setData]=useState()
    const [label,setLabel]=useState()
    const {setCacheValue, getCacheValue} = useAppContext()
    
    function getCacheKey(label,since, until, accounts){
        let params =new URLSearchParams({
            label:label,
            since: new Date(since.toISOString().substring(0, 10)).toISOString(),
            until: new Date(until.toISOString().substring(0, 10)).toISOString()
        })
        accounts?.forEach(a=>params.append("a",a))
        return params.toString()
    }


    useEffect(()=>{
        if (accounts && since && until && (label || default_label) ){
            let cacheKey = getCacheKey((label || default_label), since, until, accounts)
            if (getCacheValue(cacheKey)){
                setData(getCacheValue(cacheKey))
            }
            getApi().getAuthorAnalytics({
                project_id, 
                by_label: label || default_label, 
                since:since,
                until:until,
                accounts:accounts
            }).then(newData=>{
                setData(newData)
                setCacheValue(cacheKey, newData)
            })
        }
    },[accounts, label, since, until])

    return (<Box>
        <Text weight={900}>Author ranking by label</Text>
        <Box direction='row' justify='between' pad="small">

        <LabelPicker labels={all_labels} value={label||default_label} onValueChanged={setLabel}/>
        {/* <DateRange options={{
            "This month":()=>({since:getRelativeDate(-0), until:new Date()}),
            "Previous month":()=>({since:getRelativeDate(-1), until:addSeconds(getRelativeDate(0),-1)}),
            "2 months ago":()=>({since:getRelativeDate(-2), until:addSeconds(getRelativeDate(-1),-1)}),
        }}
        onRangeChanged={({since,until}) => {
            let val = addSeconds(since,60*60*24).toISOString().substring(0,7)
            setMonth(val)
        }}
        /> */}
        </Box>
    <Box gap="xsmall" pad="0px 20px">
        {data?.map((a,i)=>(
            <Box key={i} direction='row' onClick={()=>onAuthorPostClick&onAuthorPostClick(a)} border round="5px" pad="5px">
                <Box width="30px" flex={false} align="center">
                <Box alignSelf='start' pad="0px 5px" background="lightgray" round="large" justify='end' direction='row' flex={false}>
                    <Text size='small'>{i+1}</Text>
                </Box>
                </Box>
                <Box pad="0px 5px" flex={false}>
                            <FaUserCircle size="30px" color="lightgray" />
                </Box>
            <Box>
                
                <Box direction='row' gap="small">
                    <Text>{a.author.name}</Text>
                    <Box direction='row' gap="small" round background="lightgray" align="center" pad="0px 10px">
                    <Text size="small">{a.label}</Text>
                    <Text weight={900}>{a.count}</Text>
                    </Box>
                </Box>
                <Box direction='row' gap="small"  height="1em">
                    <Text size="xsmall" width="150px">Example: </Text>
                    <Text size="small"  truncate="tip">{a.example}</Text>
                </Box>
            </Box>
            </Box>
        ))}

    </Box>
    </Box>)
}