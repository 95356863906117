import {FaYoutube,FaFacebook,FaInstagram} from "react-icons/fa"
import { Box } from "grommet"
import styled from "styled-components"

const InstagramBox = styled(Box)`
width: 23px;
background: #f09433; 
background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
`;

export const PlatformIcon = ({platformName, active})=>{

    if (platformName=="youtube"){
        return (
            <Box background="white">
                <FaYoutube size="22px"  color={active?"red":"gray"}/>
            </Box>
        )
    }
    else if (platformName=="instagram"){
        const instagramStyle={
            background: "#f09433",
            
        }
        return (
            <InstagramBox round pad="3px">
                <FaInstagram size="17px" color="white"/>
            </InstagramBox>
        )
    }
    else if (platformName=="facebook"){
        return (
            <Box background="white" round overflow="hidden">
                <FaFacebook size="22px" color={active?"#1877f2":"gray"}/>
            </Box>
        )
    }
    else
        return (<></>)

}

PlatformIcon.defaultProps = {
    active:true
};