import { Box, Form, TextInput, FormField, Button, Heading, Grommet, grommet, Text, Spinner, Avatar, Tab, Tabs, InfiniteScroll } from 'grommet';
import { Refresh } from 'grommet-icons';
import { useEffect, useMemo, useState } from 'react';
import { useAppContext } from '../../appContexst';
import getApi from '../../Middleware/apiService';
import { AccountsFilter } from '../AccountsFilter/AccountsFilter';
import { CommentBox } from '../CommentBox/CommentBox';
import { LinkedAccountTag } from '../LinkedAccountTag/LinkedAccountTag';

export const Comments = ({project_id, mode, projectInfo, owner_post_key, cacheKey, since, until, author_id, filter, allAccounts, onExplainPredictionsClick,selectedAccounts=null, onSelectedAccountsChange=undefined }) => {
    

    
    const pageSize=100;
    const [comments,setComments] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(undefined);
    const [hasNextPage, setHasNextPage] = useState(true);

  

    useEffect(()=>{
        if (comments===undefined){
            reload(selectedAccounts)
        }
        
    },[])

  

    const {setCacheValue, getCacheValue} = useAppContext()



    function getCacheKey( accounts){
        let params =new URLSearchParams()
        params.append("cacheKey",cacheKey)
        accounts?.forEach(a=>params.append("a",a))
        return params.toString()
    }

    const loadData = (project_id, page, accounts)=>{
       
        accounts=accounts || selectedAccounts
        
        if (accounts){
            let _cacheKey = cacheKey? getCacheKey(accounts):undefined;
            if (page==1 && _cacheKey && getCacheValue(_cacheKey)){
                setCurrentPage(1)
                setComments( getCacheValue(_cacheKey));
                setIsLoading(false);    
                return
            }
            setIsLoading(true);    
        
            getApi().getComments({
                project_id, 
                filter, 
                author:author_id, 
                owner_post_key, 
                since:since,
                until:until,
                accounts:accounts, 
                skip:pageSize*(page-1), take:pageSize
            }).then(data=>{
                setCurrentPage(page)
                if(page>1&&comments){
                    
                    if (comments.length){
                        data = comments.concat(data);
                    }
                    setComments(data);
                    
                   
                }
                else{
                    

                    setComments(data||[])
                    
                    
                }
                if (_cacheKey) setCacheValue(_cacheKey, data);
                if (!(data?.length>=pageSize)){
                    setHasNextPage(false)
                }
            })
            .finally(()=>setIsLoading(false));
        }
        
       
      }
    
      const loadNextPage = ()=>{       
        loadData(project_id, currentPage+1);
      }

      function reload(accounts){
        setIsLoading(true)
        cacheKey && setCacheValue(cacheKey,undefined)
        if(accounts||selectedAccounts!=selectedAccounts)
            onSelectedAccountsChange&&onSelectedAccountsChange(accounts||selectedAccounts)
        loadData(project_id,1, accounts||selectedAccounts)
    }
    const label_settings=useMemo(()=>{
        if (projectInfo){

            if (projectInfo.labels_settings)
            return projectInfo.labels_settings;
            else{
                let val={}
                projectInfo.labels.forEach(l=>val[l]=null)
                return val
            }
        }

    },[projectInfo])

    return (
        <Box >
          {!owner_post_key?(  
          <Box direction='row' margin="10px 0px" flex={false} wrap>
            {<AccountsFilter allAccounts={allAccounts} onSelectedAccountsChange={(val)=>{
                onSelectedAccountsChange(val)
                reload(val)
                }} selectedAccounts={selectedAccounts}/>}      
            </Box>):<></>}
            
            <Box flex="grow"  
                //height="78vh"  
                pad="10px"
                overflow="auto">
               
                {
                    comments?.length?(
                    // <InfiniteScroll 
                    
                    // items={comments} 
                    // step={5}
                    // //chromatic= {{ disable: true }}
                    
                    // // renderMarker={(marker)=>{
                    // //         if (hasNextPage || isLoading){
                    // //             return (<Box margin="small" align="center"><Spinner/> loading...{!isLoading && marker}</Box>)
                    // //         }
                    // //         else return (<Box margin="5px 30px" pad="2px 2px" round background="rgb(240,240,240)" align="center">{marker}<Text size='10pt'> the end (no more data) </Text>{(marker)}</Box>)
                    // //     }
                    // //     } 
                    // // onMore={() => { 
                        
                       
                    // // }
                    // // }    
                    // >
                    // {(c,i, ref)=>(
                    //     <Box ref={ref} key={i} flex={false}>
                        
                    //     <CommentBox mode={mode} label_settings={label_settings } comment={c} onExplainPredictionsClick={onExplainPredictionsClick} />
                    //     {(i==comments?.length-1 && hasNextPage)?(
                    //         <Box onClick={()=>  loadNextPage()}>Load more</Box>
                    //       ):(<>{i} {comments?.length-1}</>)
                    //     }
                    //     </Box>
                    // )}
                    // </InfiniteScroll>
                    <Box style={{overflow:"auto"}}>
                        {comments.map((c,i)=> (
                        <Box key={i} flex={false} >    
                            <CommentBox mode={mode} label_settings={label_settings } comment={c} onExplainPredictionsClick={onExplainPredictionsClick} />
                            {(i==comments?.length-1 && hasNextPage)?(
                                <Box pad="0px 10px" background="brand" round alignSelf='center' onClick={()=>  loadNextPage()}>Load more</Box>
                            ):(<></>)
                            }
                        </Box>))
                        }
                        </Box>
                    ):(
                        isLoading!==undefined&&comments?.length==0&&<Box>Sorry, no data</Box>
                    )
                    }
                     {isLoading ?  <Spinner alignSelf='center' />:<></>}
                
              
                {/* {comments && comments.map && comments.map(c => (
                    <CommentBox key={c._id} comment={c} onExplainPredictionsClick={onExplainPredictionsClick} />
                ))} */}
            </Box>
        </Box>

    )

}