import {FaYoutube,FaFacebook,FaInstagram} from "react-icons/fa"
import { Box } from "grommet"
import styled from "styled-components"



export const RoundSmallButton = ({icon, active, onClick})=>{

    const hoverStyle={background:"rgb(240,240,240)"}
   return (<Box 
    focusIndicator={false} 
    hoverIndicator={hoverStyle} 
    pad="5px"
    round 
    onClick={onClick} 
    > 
    {icon} 
    </Box>);

}

RoundSmallButton.defaultProps = {
    active:true
};