import { Box, Text } from "grommet";
import { Refresh } from "grommet-icons";
import { useEffect, useState } from "react";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { LinkedAccountTag } from "../LinkedAccountTag/LinkedAccountTag";

const AccountsFilter = ({allAccounts, selectedAccounts, onSelectedAccountsChange})=>{
    const [selectedAccountsInternal, _setSelectedAccountsInternal] = useState();
    const [inactiveVisible, setInactiveVisible] = useState();
    function toggleAccount(account_key){
        if (selectedAccountsInternal?.includes(account_key)){
            _setSelectedAccountsInternal(selectedAccountsInternal.filter(a=>a!=account_key))
        }
        else(
            _setSelectedAccountsInternal([...selectedAccountsInternal, account_key])
        )

    }

    function setSelectedAccounts(val){
        _setSelectedAccountsInternal(val)
        onSelectedAccountsChange&&onSelectedAccountsChange(val)
    }

    useEffect(()=>{
        if (selectedAccounts !=selectedAccountsInternal){
            _setSelectedAccountsInternal(selectedAccounts)
        }
    },[selectedAccounts])

    return (
        <Box>
    <Box direction="row" pad="5px" wrap>
    {allAccounts?.filter(t=>t.active!==false).map((a,i)=><LinkedAccountTag
        key={i} 
        account={a}
        isActive={!(selectedAccountsInternal?.length) || selectedAccountsInternal?.includes(a.original_key) }
        onClick={()=>toggleAccount(a.original_key)}
        />)}
        {allAccounts?.find(t=>t.active==false)&&(
            inactiveVisible?(
                <Box border direction="row" align="center" justify="center" pad="0px 10px" gap="5px" round >
                    <Box direction="row" focusIndicator={false} onClick={()=>setInactiveVisible(!inactiveVisible)}>
                    <BiLeftArrow color="gray"/>
                    <Text size="small"  color="gray">Hide inactive</Text>
                    </Box>
                    {allAccounts?.filter(t=>t.active===false).map((a,i)=><LinkedAccountTag
                        key={i} 
                        account={{name:a.name}}
                        isActive={!(selectedAccountsInternal?.length) || selectedAccountsInternal?.includes(a.original_key) }
                        onClick={()=>toggleAccount(a.original_key)}
                    />)}
                </Box>
            ):(
                <Box border direction="row" align="center" justify="center" pad="0px 10px" gap="5px" round focusIndicator={false} onClick={()=>setInactiveVisible(!inactiveVisible)}>
                    <Text size="small" color="gray">Show inactive</Text>
                    <BiRightArrow color="gray"/>
                </Box>
            )

        )}
    </Box>
    <Box  direction="row" margin="5px 0px" justify="end">
         {selectedAccountsInternal!=selectedAccounts && 
            <Box direction='row' align='center' justify='center' border  focusIndicator={false} 
                margin="0px 10px" 
                round="large" pad="5px 10px" gap='small'
                background={selectedAccountsInternal!=selectedAccounts?"brand":undefined}
                onClick={() => setSelectedAccounts(selectedAccountsInternal)}>

                <Refresh size="20px" />
                <Text>{selectedAccountsInternal!=selectedAccounts? "Apply change":"Refresh"}</Text>
            </Box>
        }
    </Box>
    </Box>
    )
}

export {AccountsFilter}